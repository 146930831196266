import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { UploadEnclosureComponent } from './upload-enclosure/upload-enclosure.component';
import { ApplicantEnclosureListComponent } from './applicant-enclosure-list/applicant-enclosure-list.component';
import { HelpDeskLoginComponent } from './help-desk-login/help-desk-login.component';
import { OnlineApplicationLoginComponent } from './online-application-login/online-application-login.component';
import { ApplicationLoginTypeComponent } from './application-login-type/application-login-type.component';
import { CommunityLoginComponent } from './community-login/community-login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminComponent } from './admin/admin.component';
import { AuditHelpdeskComponent } from './audit-helpdesk/audit-helpdesk.component';
import { AuditHelpdeskLoginComponent } from './audit-helpdesk-login/audit-helpdesk-login.component';
import { PreviewEnclosureComponent } from './preview-enclosure/preview-enclosure.component';
import { ScrutinyLoginComponent } from './scrutiny-user/scrutiny-login/scrutiny-login.component';
import { ScrutinyPreviewComponent } from './scrutiny-user/scrutiny-preview/scrutiny-preview.component';
import { ActivityLogComponent } from './scrutiny-user/activity-log/activity-log.component';
import { ScrutinyDashboardComponent } from './scrutiny-user/scrutiny-dashboard/scrutiny-dashboard.component';
import { ScrutinyAdminDashboardComponent } from './scrutiny-admin/scrutiny-admin-dashboard/scrutiny-admin-dashboard.component';
import { ScrutinyAdminLoginComponent } from './scrutiny-admin/scrutiny-admin-login/scrutiny-admin-login.component';
import { ActivityLogViewComponent } from './scrutiny-user/activity-log-view/activity-log-view.component';
import { FieldVisitUserDashboardComponent } from './field-visit-user/field-visit-user-dashboard/field-visit-user-dashboard.component';
import { FieldVisitUserLoginComponent } from './field-visit-user/field-visit-user-login/field-visit-user-login.component';
import { FieldVisitUserActivityLogComponent } from './field-visit-user/field-visit-user-activity-log/field-visit-user-activity-log.component';
import { FieldVisitWorkbenchComponent } from './field-visit-user/field-visit-workbench/field-visit-workbench.component';
import { FieldVisitActivityLogViewComponent } from './field-visit-user/field-visit-activity-log-view/field-visit-activity-log-view.component';
import { PrintDialogAcknowledgementComponent } from './application-form/print-dialog-acknowledgement/print-dialog-acknowledgement.component';
import { CommiteeWorkbenchComponent } from './applicant-list/commitee-workbench/commitee-workbench.component';

const routes: Routes = [
  {
    path: 'application_login',
    pathMatch: 'full',
    component: ApplicationFormComponent,
  },
  {
    path: 'applicant-list',
    pathMatch: 'full',
    component: ApplicantListComponent,
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'applicant-form',
    component: ApplicantFormComponent,
    children: [
      {path: '**', redirectTo: 'upload-enclosure'},
      {path: 'upload-enclosure', component: UploadEnclosureComponent},
    ]
  },
  {
    path: 'applicant-enclosure-list',
    pathMatch: 'full',
    component: ApplicantEnclosureListComponent,
  },
  {
    path: 'helpdesk-login',
    pathMatch: 'full',
    component: HelpDeskLoginComponent,
  },
  {
    path: 'scrutiny-login',
    pathMatch: 'full',
    component: ScrutinyLoginComponent,
  },
  {
    path:'scrutiny-admin-login',
    pathMatch: 'full',
    component: ScrutinyAdminLoginComponent,
  },
  {
    path:'scrutiny-admin-list',
    pathMatch: 'full',
    component: ScrutinyAdminDashboardComponent,
  },
  {
    path: 'scrutiny-preview',
    pathMatch: 'full',
    component: ScrutinyPreviewComponent,
  },
  {
    path: 'activity-log-view',
    pathMatch: 'full',
    component: ActivityLogViewComponent,
  },
  {
    path: 'preview-enclosure',
    pathMatch: 'full',
    component: PreviewEnclosureComponent,
  },
  {
    path: 'online-applicant-login',
    pathMatch: 'full',
    component: OnlineApplicationLoginComponent,
  },
  {
    path: 'application',
    pathMatch: 'full',
    component: ApplicationLoginTypeComponent,
  },
  {
    path: 'committee-login',
    pathMatch: 'full',
    component: CommunityLoginComponent,
  },
  {
    path: 'admin-login',
    pathMatch: 'full',
    component: AdminLoginComponent,
  },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
  },
  {
    path: 'audit-helpdesk',
    pathMatch: 'full',
    component: AuditHelpdeskComponent,
  },
  {
    path: 'scrutiny-user-dashboard',
    pathMatch:'full',
    component: ScrutinyDashboardComponent,
  },
  {
    path: 'audit-helpdesk-login',
    pathMatch: 'full',
    component: AuditHelpdeskLoginComponent,
  },
  {
    path: 'activity-log',
    pathMatch:'full',
    component: ActivityLogComponent,
  },
  {
    path: 'field-visit-login',
    pathMatch:'full',
    component: FieldVisitUserLoginComponent,
  },
  {
    path: 'field-visit-user-dashboard',
    pathMatch:'full',
    component: FieldVisitUserDashboardComponent,
  },
  {
    path: 'field-visit-user-activity-log',
    pathMatch:'full',
    component: FieldVisitUserActivityLogComponent,
  },
  {
    path: 'field-visit-workbench',
    pathMatch:'full',
    component: FieldVisitWorkbenchComponent,
  },
  {
    path: 'field-visit-activity-log-view',
    pathMatch:'full',
    component: FieldVisitActivityLogViewComponent,
  },
  {
    path: 'print-dialog-acknowledgement',
    pathMatch:'full',
    component: PrintDialogAcknowledgementComponent,
  },
  {
    path: 'commitee-workbench',
    pathMatch:'full',
    component: CommiteeWorkbenchComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }