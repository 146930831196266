import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';
import { ApplicantEnclosureListComponent } from '../applicant-enclosure-list/applicant-enclosure-list.component';
import { UserService } from 'src/services/user-service/user.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StaticsComponent } from '../statics/statics.component';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-applicant-list',
  templateUrl: './applicant-list.component.html',
  styleUrls: ['./applicant-list.component.scss']
})
export class ApplicantListComponent implements OnInit {

  @ViewChild(StaticsComponent) staticsComponent;

  // public displayedColumns: string[] = ['applicationNo', 'applicantName','telephoneNumber','action'];

  public displayedColumns: string[] = ['sn','applicationNo'];

  public dataSource = new MatTableDataSource();

  public panchayats : any[] = [];

  public villages : any[] = [];

  public isLoading = false;

  public selectedPanchayat;

  public selectVillage;

  public userName = "Username";

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];

  public isCommitteeUser;

  public faSync = faSync;

  public pageSize = 5;

  public currentPage = 0;

  public totalSize = 0;

  public isRefreshing = false;
  user: any;

  constructor(private router: Router, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService, private roleConfig: RoleConfigService,private strorageService:StrorageService) { }

  ngOnInit(): void {
    // let userType = this.userService.getUserType();
    // if(userType != this.userService.COMITTEE_USER || userType != this.userService.SCRUTINY_USER){
    //     this.router.navigate(['/'])
    // }
    this.user = this.strorageService.getUser();
    this.userName=this.user['firstName'];
    this.isCommitteeUser = this.roleConfig.ROLE_COMMITTEE;
    if(this.isCommitteeUser)
    this.fetchAllPanchayats();
  }

  onStaticsClick(){
    if(!this.isCommitteeUser)
    this.fetchAllPanchayats();
  }


  onPreviewClick = (id: any) => {
    this.router.navigate(['/commitee-workbench'], {queryParams:{application_id:id}});
   }


  fetchAllPanchayats() {
     this.applicationService.fetchAllPanchayats().subscribe(res =>{
         this.panchayats = res;
         
     })
  }

  onPanchayatChange(id){
    this.fetchVillagesByPanchayat(id);
    this.selectedPanchayat = id;
  }

  onVillageChange(id){
    this.fetchApplicantsByVillage(id);
    this.selectVillage = id;

  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    this.staticsComponent.fetchAllApplicant();
    if(this.isCommitteeUser)
    this.fetchAllPanchayats();
  }
   public getApplicantPaginatorData = (event: PageEvent) => {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchApplicantsByVillage(this.selectVillage);
  }


  public goDetailsPage(data){
    this.openEnclosure(data );
    // this.router.navigate(['/applicant-enclosure-list'], { queryParams: { applicantId: row['id'] } });
  }
  openEnclosure(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data =data.id;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }
 

  fetchVillagesByPanchayat(id){
   // this.isLoading = true;
    this.applicationService.fetchAllVillagesByPanchayat(id).subscribe(res =>{
      this.villages = res;
    //  this.isLoading = false;
   })
  }

  
  private fetchAllApplicants(): void {
   // this.isLoading = true;
    this.applicationService.getApplicants().subscribe(res =>{
      this.dataSource.data = res;
     // this.isLoading = false;
    })
  }

  

  private fetchApplicantsByVillage(id){
    if(!this.isCommitteeUser){
      return;
    }
   // this.isLoading = true;
    this.applicationService.fetchApplicantByVillages(id,this.currentPage, this.pageSize).subscribe(res =>{
    this.refreshDataTable(res);
      console.log(res);
     // this.isLoading = false;
    })
  }
  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }

}
