<div class="content-area">
<div class="col-sm-12">

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.saleDeed"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.saleDeed"></fa-icon>
            <label> 1.ಮೂಲ ಜಮೀನು ಮಾಲೀಕರಿಂದ ನಿವೇಶನ ಖರೀದಿಗೆ ಸಂಬಂಧಿಸಿದ ಮಾರಾಟ ಪತ್ರಗಳ ಪ್ರಮಾಣೀಕೃತ ಪ್ರತಿ/ Certified copy of the sale deeds regarding purchase of the site from original land owner <span class="text-danger"> *</span></label>
        </div>
        <div class="col-md-4">
            <input type="file" id="sale_deed" [(ngModel)]="saleDeed" [disabled]="(enclosure && enclosure.saleDeed) && !isEditable" name="sale_deed" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('sale_deed')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.encumberanceCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.encumberanceCert"></fa-icon>
            <label> 2.ನಿವೇಶನ ಖರೀದಿಸಿದ ದಿನಾಂಕದಿಂದ ಈ ತನಕದ ಋಣಭಾರ / ಋಣಭಾರರಾಹಿತ್ಯ ಪತ್ರ (ಇ . ಸಿ) - ನಮೂನೆ - 15 / Encumbrance Certificate in form 15 from the date of purchase of revenue site till date</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="encumberance_cert" [disabled]="(enclosure && enclosure.encumberanceCert)  && !isEditable" name="encumberance_cert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('encumberance_cert')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.buildingSitePic"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.buildingSitePic"></fa-icon>
            <label> 3.ಕಟ್ಟಡದ ಭಾವಚಿತ್ರ/ Photos of the Site with building </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="building_site_pic" [disabled]="(enclosure && enclosure.buildingSitePic) && !isEditable" name="building_site_pic" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('building_site_pic')"></fa-icon>
        </div>
    </div>



    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.taxPaidReceipt"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.taxPaidReceipt"></fa-icon>
            <label> 4.ಪ್ರಸಕ್ತ ವರ್ಷದ ತೆರಿಗೆ ಪಾವತಿಸಿದ ರಸೀದಿಗಳು/ Tax paid receipts of the current year  </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="tax_paid_receipt" [disabled]="(enclosure && enclosure.taxPaidReceipt) && !isEditable" name="tax_paid_receipt" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('tax_paid_receipt')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.khataCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.khataCopy"></fa-icon>
            <label> 5.ಖಾತಾದ ಪ್ರಮಾಣೀಕೃತ ಪ್ರತಿ/ Certified copy of the Khata   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="khata_cpy" [disabled]="(enclosure && enclosure.khataCopy) && !isEditable" name="khata_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('khata_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.khataExtract"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.khataExtract"></fa-icon>
            <label> 6.ಖಾತಾ ಸಾರ/ Khata Extract   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="khata_extract" [disabled]="(enclosure && enclosure.khataExtract) && !isEditable" name="khata_extract" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('khata_extract')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.waterConnCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.waterConnCopy"></fa-icon>
            <label> 7.ನೀರಿನ ಸಂಪರ್ಕಗಳ ಕೆಲಸದ ಆದೇಶದ ಪ್ರತಿ/ Copy of the work order of water connections   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="water_conn_cpy" [disabled]="(enclosure && enclosure.waterConnCopy) && !isEditable" name="water_conn_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('water_conn_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.electricityConnCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.electricityConnCopy"></fa-icon>
            <label> 8.ವಿದ್ಯುತ್ ಸಂಪರ್ಕಗಳ ಕೆಲಸದ ಆದೇಶದ ಪ್ರತಿ/ Copy of the work order of electricity connections   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="electricity_conn_cpy" [disabled]="(enclosure && enclosure.electricityConnCopy) && !isEditable" name="electricity_conn_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('electricity_conn_cpy')"></fa-icon>
        </div>
    </div>


    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.addressProof"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.addressProof"></fa-icon>
            <label> 9.ವಿಳಾಸ ಪುರಾವೆ (ಆಧಾರ್, ಮತದಾರರ ಗುರುತಿನ ಚೀಟಿ, ಪಡಿತರ ಚೀಟಿ ಇತ್ಯಾದಿ)/Address Proof (AADHAAR, Voter ID, Ration card etc)<span class="text-danger"> *</span> </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="address_proof" [(ngModel)]="addressProof" [disabled]="(enclosure && enclosure.addressProof) && !isEditable" name="address_proof" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('address_proof')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.residenceProof"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.residenceProof"></fa-icon>
            <label> 10.ಕಳೆದ 12 ವರ್ಷಗಳಿಂದ ಸದರಿ ಕಟ್ಟಡದಲ್ಲಿ ವಾಸದ ಪುರಾವೆ/ Authorized Proof of residence there for the past 12 years   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="residence_proof" [disabled]="(enclosure && enclosure.residenceProof) && !isEditable" name="residence_proof" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('residence_proof')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.formAffidavit"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.formAffidavit"></fa-icon>
            <label> 11.ಅಫಿಡವಿಟ್/ Affidavit   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="form_affidavit" [disabled]="(enclosure && enclosure.formAffidavit) && !isEditable" name="form_affidavit" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('form_affidavit')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.otherRelatedDocs"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.otherRelatedDocs"></fa-icon>
            <label> 12.ಇತರೆ ಸಂಬಂಧಿತ ದಾಖಲೆಗಳು / Other Related Documents   </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="other_related_docs" [disabled]="(enclosure && enclosure.otherRelatedDocs) && !isEditable" name="other_related_docs" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('other_related_docs')"></fa-icon>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-2 offset-md-5 button-row">
            <input type="button" class="btn btn-primary" value="Save" (click)="onSubmit()"/>
        </div>
    </div>
</div>