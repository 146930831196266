<div class="content-area">
<div fxLayout="row">
    <div class="col-sm-10 offset-1 footer-text footer">
        Content and Data Owned & Maintained by BDA 2022
        <div><img src="../assets/Navigem-logo.png" class="logo"/></div>   
    </div>
</div>
<div fxLayout="row">
    <div class="col-sm-12 footer-text">
  <!-- |All Rights Reserved| Designed, Developed & Powered by Navigem Data -->
    </div>
</div>
</div>

