import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/services/api.service';
import { UserService } from 'src/services/user-service/user.service';
import { StrorageService } from 'src/services/strorage.service';

@Injectable()
export class TokenInterceptorService {

  constructor(public apiService: ApiService, private storageService: StrorageService) {} 

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let header = {};
    let accessToken = this.storageService.getAccessToken();
    if(accessToken){
      header['Authorization'] = accessToken;
    }
    request = request.clone({
      setHeaders: header
    });    return next.handle(request);
  }
}

