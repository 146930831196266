import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StrorageService } from './strorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private apiService: ApiService, private storageService: StrorageService) { }

  saveApplication(payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants/basicDetails', payload);
  }

  getApplicants(filters?: any): Observable<any> {
    return this.apiService.post('/v1/get_applicants', filters);
  }
  getActivityLogs(page?: any, size?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let envelop = {};
    envelop['page'] = page;
    envelop['size'] = size;
    return this.apiService.post('/v1/scrutiny/activity_logs', envelop);
  }
  getScrutinyUserApplicants(filters?: any, page?: any, size?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let envelop = {};
    envelop['page'] = page;
    envelop['size'] = size;
    if (filters) {
      envelop['filters'] = filters;
    }
    return this.apiService.post("/v1/scrutiny_user/applicants", envelop);
  }

  getScrutinyUserDashboardFilterCount(): Observable<any> {
    return this.apiService.get("/v1/scrutiny_user/record_count");
  }
  getAges(): Observable<any> {
    return this.apiService.get("/v1/ages");
  }
  saveFieldVisit(id: any, payload: any): Observable<any> {
    return this.apiService.post("/v1/applicants/" + id + "/field_visit", payload);
  }

  //Field Visit User API
  getFieldUserApplicants(page?: any, size?: any): Observable<any> {
    let user = this.storageService.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }
    let villageId = user['villageDTO'].id;
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    let envelop = {};
    envelop['page'] = page;
    envelop['size'] = size;
    return this.apiService.get("/v1/field_visit_user/villages/" + villageId + "/applicants?page=" + page + "&size=" + size);
  }

  getFieldVisitStatus() {
    return this.apiService.get("/v1/field_visit_user/status");
  }

  getLoginSettings() {
    return this.apiService.get("/v1/login_settings");
  }


  getFieldVisitActivityLog(page?: any, size?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    return this.apiService.get('/v1/field_visit_user/activity_logs?page=' + page + '&' + 'size=' + size);
  }
  getFieldVisitActivityLogDetails(id: any) {
    return this.apiService.get('/v1/field_visit/applicants/' + id + '/activity_log_details');
  }
  // field visit user api end
  fetchAllVillages(): Observable<any[]> {
    return this.apiService.get('/v1/villages');
  }
  fetchAllBuildingTypes(): Observable<any[]> {
    return this.apiService.get('/v1/buildingType');
  }
  fetchSurveyNoByVillageId(id): Observable<any[]> {
    return this.apiService.get('/v1/surveyMappers?villageId=' + id);
  }
  fetchAllVillagesByPanchayat(id): Observable<any[]> {
    return this.apiService.get('/v1/villages?panchayat=' + id);
  }

  fetchAllApplicantByVillage(id): Observable<any[]> {
    return this.apiService.get('/v1/applicants?village=' + id);
  }
  fetchAllApplicantByBuildingType(id): Observable<any[]> {
    return this.apiService.get('/v1/applicants?buildingType=' + id);
  }
  fetchAllApplicant(): Observable<any[]> {
    return this.apiService.get('/v1/applicantsCount');
  }
  fetchApplicantCountByVillage(id): Observable<any[]> {
    return this.apiService.get('/v1/applicantsCount?village=' + id);
  }
  fetchApplicantCountByPanchayat(id): Observable<any[]> {
    return this.apiService.get('/v1/applicantsCount?panchayat=' + id);
  }
  fetchApplicantByApplicationNo(applicationNo): Observable<any> {
    return this.apiService.get('/v1/application?applicationNo=' + applicationNo);
  }
  saveSiteDetailsWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_details/one_time_edit', payload)
  }
  saveSiteApprovalWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_approval/one_time_edit', payload)
  }
  saveBuildingDetailsWithOneTimeEdit(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + 'building_details/one_time_edit', payload)
  }

  fetchApplicationNo(searchKey: string): Observable<any[]> {
    if (searchKey) {
      return this.apiService.get('/v1/applicationNo?searchQuery=' + searchKey);
    }
    return this.apiService.get('/v1/applicationNo');
  }
  fetchApplicants(): Observable<any[]> {
    return this.apiService.get('/v1/applicationNo');
  }
  saveScrutinyReferField(id: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/scrutinyFieldRefer');
  }
  saveScrutinyComment1(id: any, payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants/' + id + '/scrutiny_comments', payload);
  }

  updateScrutinyStatus(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/scrutiny_status', payload);
  }

  fetchAllApplicantByPanchayat(id): Observable<any[]> {
    return this.apiService.get('/v1/applicants?panchayat=' + id);
  }

  fetchAllPanchayats(): Observable<any[]> {
    return this.apiService.get('/v1/panchayat');
  }

  getApplicantEnclosureList(id: any): Observable<any> {
    return this.apiService.get('/v1/applicants/' + id + "/enclosures");
  }

  fetchApplicantById(id: any): Observable<any> {
    return this.apiService.get('/v1/applicant/' + id);
  }

  saveSiteDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_details', payload);
  }

  updateBasicSiteDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/basicDetails', payload);
  }

  updatePersonalDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/personalDetails', payload);
  }
  updatePropertyDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/propertyDetails', payload);
  }
  updateCourtDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/courtDetails', payload);
  }

  saveSiteApproval(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/site_approval', payload);
  }

  saveBuildingDetails(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/building_details', payload);
  }

  uploadDocuments(id: any, payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants/' + id + '/upload_document', payload);
  }

  uploadPropertyPicture(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/upload_property_pic', payload);
  }

  getApplicantStatistics(): Observable<any> {
    return this.apiService.get('/v1/applicantStatitics');
  }

  getApplicantCountByDate(): Observable<any> {
    return this.apiService.get('/v1/applicantCountByDate');
  }

  getApplicantCountByDateFilter(fromDate, toDate): Observable<any> {
    return this.apiService.get('/v1/applicantCountByDate?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  overrideApplication(applicationNo): Observable<any> {
    return this.apiService.post('/v1/overrideApplication/' + applicationNo);
  }

  auditHelpdeskEntry(payload): Observable<any> {
    return this.apiService.post('/v1/auditHelpdesk', payload);
  }

  getAuditHelpdeskStatistics(): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics');
  }

  getAuditHelpdeskStatisticsByDate(fromDate, toDate): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  updateLock(id: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + id + '/application_lock_status', payload);
  }

  getScrutinyUserCommentTypeRef(): Observable<any> {
    return this.apiService.get('/v1/scrutiny_user/comment_type_ref');
  }

  activateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/activate_one_time_edit');
  }

  deActivateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/deactivate_one_time_edit');
  }

  closeApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/' + applicantId + '/close_one_time_edit');
  }
  fetchApplicantByVillages(villageId?: any, page?: any, size?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    return this.apiService.get('/v1/committee/applicantByVillages?villageId=' + villageId + '&' + 'page=' + page + '&' + 'size=' + size)
  }
  fetchApplicantByBuildingType(buildingTypeId?: any, page?: any, size?: any): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!size) {
      size = 10;
    }
    return this.apiService.get('/v1/committee/applicantBybuildingType?buildingTypeId=' + buildingTypeId + '&' + 'page=' + page + '&' + 'size=' + size)
  }
}
