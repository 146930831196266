import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'highcharts';


@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {

  public data: any;

  public isEditable: any;

  public menus: any = [
    {
      name: 'Personal Details',
      type: 'link',
      active: true,
      code: 'basic-info'
    }
  ];

  public applicantName: string;


  public maxDate = new Date().getTime();


  // public registrationNo  :string;

  public fatherName: string;

  public occupation: string;

  public registrationFees: string = '5500'; //default value

  public applicantDob: Date;

  public presentAddress: string;

  public address1: string;

  public address2: string;

  public telephoneNumber: number;

  public mobileNumber: number;

  public aadharNo: number;

  // public city : string;

  public pinCode: string;

  public email: string;

  public surveyNumber: string;

  // public aadharNumber: number;

  public villages: any[];

  public surveys: any[];

  public age: number;

  // public selectedVillage;

  public applicantAddress: string;

  public selectedMenu: any = {};

  private applicantId: any;

  public siteNo: any;

  public basicdetailssurveynohissa: any;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();
  // address: any;
  state: any;
  country: any;
  // dateofRegister: any;
  villageId: any;
  basicDetailsSurveyNoHissa: any;
  public ages: any = [];

  constructor(private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService) { }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    // this.getAllVillages();
    this.getAges();
    this.fetchApplication();
  }


  private fetchApplication = () => {
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if (encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if (!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res => {
      this.applicantId = res.id;
      this.setEditable(res);
      this.data = res;
      this.setValue(res);
      this.newApplicantCallback.emit(res);
    })
  }

  getAges() {
    this.applicationService.getAges().subscribe(res => {
      console.log(res);
      this.ages = res;
    })
  }

  getAllVillages() {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villages = res;
      // this.villageId=this.villages.indexOf(this.selectedVillage)
      console.log(this.villages);
      this.fetchApplication();
      //this.villageId=res.id;
    }, err => {
      console.log("personal details component getVillages() err", err);
    }, () => {
      console.log("personal details component getVillages() completed");
    })
  }


  onVillageChanged = (event: any, data?: any) => {
    this.applicationService.fetchSurveyNoByVillageId(event).subscribe(res => {
      this.surveys = res;
      if (data) {
        this.surveyNumber = data.surveyMapperVO.id;
      }
      console.log(this.surveys);
    })
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res => {
      console.log("basic personal details", res);
      this.updateUI(res);
    })
  }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    this.setValue(res);
    this.newApplicantCallback.emit(res);
  }
  reset() {
    this.applicantName = null;
    this.fatherName = null;
    // this.dateofRegister= null;
    this.mobileNumber = null;
    // this.registrationNo = null;
    // this.registrationFees = null;
    this.presentAddress = null;
    //  this.email = null;
    // this.country=null;
    this.aadharNo = null;
    this.state = null;
    // this.city = null;
    this.pinCode = null;
    this.surveyNumber = null;
    this.basicDetailsSurveyNoHissa = null;
  }

  validateAndConstructPayload = () => {

    if (!this.applicantName) {
      throw new Error('Applicant name field must not be empty');
    }
    if (!this.age) {
      throw new Error('Select applicant age!');
    }
    if (!this.fatherName) {
      throw new Error('FatherName field must not be empty');
    }
    if (!this.mobileNumber) {
      throw new Error('Mobile number field must not be empty');
    }
    if (this.mobileNumber.toString().length != 10) {
      throw new Error('Please enter a valid mobile number');
    }
    if (!this.presentAddress) {
      throw new Error('Address field must not be empty');
    }
    if (!this.aadharNo){
      throw new Error('Aadhar number field must not be empty');
    }
    if (this.aadharNo.toString().length != 12){
      throw new Error('Enter a valid 12 digit Aadhaar number');
    }
    
    let payload = {};
    payload['applicantName'] = this.applicantName;
    payload['fatherName'] = this.fatherName;
    payload['mobileNumber'] = this.mobileNumber;
    payload['age'] = this.age;
    payload['presentAddress'] = this.presentAddress;
    payload['aadharNo'] = this.aadharNo;
    payload['occupation'] = this.occupation;
    console.log("payload", payload);
    // payload['country'] = this.country;
    // payload['state'] = this.state;
    // payload['applicantCity'] = this.city;
    // payload['pincode'] = this.pinCode;
    // payload['basicDetailsSurveyNoHissa'] = this.basicDetailsSurveyNoHissa;

    return payload;
  }

  setValue(data: any): void {
    console.log('data ', data)
    this.applicantName = data.applicantName;
    this.mobileNumber = data.mobileNumber;
    this.fatherName = data.fatherName;
    this.occupation = data.occupation;
    this.presentAddress = data.applicantAddress;
    this.aadharNo = data.aadharNo;
    this.age = data.applicantAge;
    // this.registrationNo = data.applicationNo;
    // if(data.registrationFees) {
    //   this.registrationFees = data.registrationFees;
    // }
    // this.dateofRegister = data.registrationDate;
    //this.address = data.address;

    // this.city = data.city;
    // this.country = data.country;
    // this.state = data.state;
    // this.email = data.email;
    //this.address = data.applicantAddress;
    // this.city = data.applicantCity;
    // this.pinCode = data.pincode;
    // this.siteNo = data.siteNo;
    // this.basicDetailsSurveyNoHissa = data.basicDetailsSurveyNoHissa;
    // this.selectedVillage = data.siteDetailsVillageVO.id;
    // this.onVillageChanged(this.selectedVillage, data);
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (this.applicantId) {
          this.updateApplicantBasicDetailsApiCall(this.applicantId);
          return;
        }
        this.saveApplicantBasicDetailsApiCall();
      }
    })
  }

  saveApplicantBasicDetailsApiCall = () => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res => {
        window.localStorage.setItem("applicantId", res.id);
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.getApplicant(res);
        this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  private getApplicant(res: any): void {
    this.applicationService.fetchApplicantById(res.id).subscribe(res => {
      this.updateUI(res);
    }, err => {
      console.log(err);
      this.openSnackbar(err?.message, "Dismiss");
    })
  }

  private setEditable = (data: any): void => {
    if (data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  updateApplicantBasicDetailsApiCall = (applicantId: any) => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.updateBasicSiteDetails(applicantId, payload).subscribe(res => {
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.getApplicant(res);
        this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }
}
