import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicantEnclosureListComponent } from 'src/app/applicant-enclosure-list/applicant-enclosure-list.component';
import { PreviewDialogComponent } from 'src/app/preview-dialog/preview-dialog.component';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnclosureService } from 'src/services/enclosure-service/enclosure.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Window } from 'selenium-webdriver';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {

public enclosures= [];
public data: any = undefined;
public village: any;
public panchayat: any;
public isEnclosureShown :any = true;
public isHelpdeskUser: any;
public isEditable = false;
public oneTimeEdit = false;
public showOneTimeEditButton = false;
public showSaveChangesBtn = false;
public faTimes = faTimes;
public isLoggedIn = false;
private user: any;

public partlyNotifiedSurveyNo:any;
public partlyNotifedSurveyNosVO:any;

private applicantId: any;
private applicantNumber: any;
public enclosure: any;
public userType;



@Output() oneTimeEditCallback = new EventEmitter<any>();

@Output() saveChangesCallback = new EventEmitter<any>();

  public enclosuresPrintData: any = [];

  @ViewChild('printData') printData: ElementRef;

  constructor(private applicationService : ApplicationService, 
    private route: ActivatedRoute, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private router : Router, public dialog: MatDialog, 
    public userService: UserService,private roleConfig:RoleConfigService, 
    private cdr:ChangeDetectorRef, private storageService: StrorageService, 
    private _snackBar: MatSnackBar, private enclosureService: EnclosureService) {
      this.user = storageService.getUser();
      if(this.user) {
        this.userType = this.user['roleCode'];
      }
     }

  ngOnInit(): void {
    let user = this.storageService.getUser();
    this.resetEnclosureData();
    //this.isEnclosureShown = (user['roleCode'] == this.roleConfig.ROLE_ONLINE_APPLICATION_USER);
    this.isHelpdeskUser = (user['roleCode'] == this.roleConfig.ROLE_HELP_DESK_USER);
    
    

   let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      this.applicantNumber = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicantData(this.applicantNumber);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
 
  }

  private resetUI = (applicantData: any) => {
    if(applicantData['oneTimeEdit'] == null) {
      this.showOneTimeEditButton = true;
    }
    if(applicantData['oneTimeEdit']) {
      this.showOneTimeEditButton = false;
      this.showSaveChangesBtn = true;
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
        this.applicantNumber = res.applicationNo;
        this.applicantId = res.id;
        this.data = res;
        // this.partlyNotifiedSurveyNo=this.data.partlyNotifedSurveyNosVO.surveyNos;
        this.updateUI(this.data);
        this.fetchEnclosureData(res.id);
        this.resetUI(res);
    })
  }
  
  fetchEnclosureData = (applicationId: any) => {
    this.applicationService.getApplicantEnclosureList(applicationId).subscribe(res =>{
      const documents = [];
      for (const [key, value] of Object.entries(res)) {
        let document = {};
        document['documentName'] = key;
        document['link'] = value;
        documents.push(document);
      }
       this.enclosures  = documents;
       console.log(' t', this.enclosures);
       this.printEnclosureData(res);
    })
  }
    resetEnclosureData =() =>{
    let enclosureData = this.enclosureService.enclosures;
    for(let i=0;i<enclosureData.length;i++){
      enclosureData[i]['active']= false;
    }

   }
  private printEnclosureData = (data: any) => {
    let enclousresData = this.enclosureService.enclosures;
    Object.keys(data).forEach(function(key) {
      console.log('Key : ' + key + ', Value : ' + data[key]);
      for(let j = 0;j < enclousresData.length;j++) {
        if(key == enclousresData[j]["id"] && data[key]) {
          enclousresData[j]["active"] = true;
        } 
      }
     
    })
    this.enclosuresPrintData = enclousresData;
    console.log(enclousresData);
  }

  private updateUI = (data: any) => {
    if(data && data.villageDTO){
      this.village = data.villageDTO.name;
    }
    if(data && data.villageDTO && data.villageDTO.panchayatDTO){
      this.village = data.villageDTO.name;
      this.panchayat = data.villageDTO.panchayatDTO.name;
    }
  }

  fetchApplicantData(applicationNo: any){
     this.applicationService.fetchApplicantByApplicationNo(applicationNo).subscribe(res =>{
      // var printData = document.getElementById("print-section").remove();
        this.applicantId = res.id;
        this.data = res;
        this.updateUI(this.data);
        this.fetchEnclosureData(res.id);
        this.resetUI(res);
     })
  }

  public goDetailsPage(data: any) {
    this.openEnclosure(data);
  }

  openEnclosure = (data: any): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data =data.id;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
       
    })
  }

  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = this.applicantNumber;
    const dialogRef = this.dialog.open(PreviewDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
    })
  }

  preparePrintData(source: any) {
    
    let data = "<html><head><style type='text/css' media='print'>@media print {display:block; @page { size: auto; margin: 0;} body { margin:1.6cm; } }</style><script>function step1(){\n" +
      "setTimeout('step2()', 2);}\n" +
      "function step2(){window.print()?dev=version=1.0.0';window.close()}\n" +
      "</script></head><body onload='step1()'>"+ source +'?' + Date.now()+"</body></html>";
      return data;
  }
  printPage(){
    window.print();
  }
  onPrint = ()=> {
    var printContents = this.printData.nativeElement.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close();
  }
  
  onDone = () => {
    if(this.userType == "online_application_user"){
      this.logout();
      return;
    }
    window.localStorage.removeItem("applicantId");
     if(this.data['oneTimeEdit']) {
      this.deActivateOneTimeEdit();
      return;
    }
    this.routeToHomePage();
  }

  private routeToHomePage = (): void => {
    let user = this.storageService.getUser();
    if(user['roleCode'] == this.roleConfig.ROLE_ONLINE_APPLICATION_USER) {
      this.router.navigate(['/application']);
    } else {
      window.scroll(0,0);
      if(user['roleCode'] == this.roleConfig.ROLE_ADMIN_USER) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/application']);
      }
    }
  }

  public closeOneTimeEdit = (): void => {
    this.applicationService.closeApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
      this.openSnackbar("Successfully closed one time edit");
      this.saveChangesCallback.emit();
    }, err => {
      console.log('close one time edit error', err);
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  public activateOneTimeEdit = () => {
    this.applicationService.activateApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
      this.isEditable = true;
      this.resetUI(res);
      this.openSnackbar("Successfully activated");
      this.oneTimeEditCallback.emit();
    }, err => {
      console.log('activate one time edit error', err);
    })
 }
 logout = () => {

  this.storageService.clear();
  this.isLoggedIn = false;
  if(this.userType == "helpdesk_user"){
    this.router.navigate(['/helpdesk-login']);
  }
  else if(this.userType == "online_application_user"){
    this.router.navigate(['/online-applicant-login']);
  }
  else if(this.userType == "comittee_user"){
    this.router.navigate(['/committee-login']);
  }
  else if(this.userType == "scrutiny_user"){
    this.router.navigate(['/scrutiny-login']);
  }
   else if(this.userType == "admin_user"){
    this.router.navigate(['/admin-login']);
   }
   else if(this.userType == "audit_helpdesk"){
    this.router.navigate(['/audit-helpdesk-login']);
   }
   else{
    this.router.navigate(['']);
   }
}

 openLogoutDialog() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = true;
  dialogConfig.height = '160px';
  dialogConfig.width = '360px';
  dialogConfig.data = "Are you sure want to save and logout ?"
  const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(res =>{
      if(res){
        this.logout();
      }
  })
}

 public deActivateOneTimeEdit = () => {
  this.applicationService.deActivateApplicantOneTimeEdit(this.applicantId).subscribe(res =>{
    this.isEditable = false;
    this.openSnackbar("Successfully deactivated");
    this.routeToHomePage();
  }, err => {
    console.log('activate one time edit error', err);
  })
}
}
