import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnclosureService {

  // USER_TYPE_CONSTANTS
  // COMPLETE_ADDRESS = "completeAddress";
  SALE_DEED = "saleDeed";
  ENCUMBERANCE_CERT= "encumberanceCert";
  BUILDING_SITE_PIC= "buildingSitePic";
  TAX_PAID_RECEIPT = "taxPaidReceipt";
  KHATA_CPY = "khataCopy";
  KHATA_EXTRACT= "khataExtract";
  WATER_CONN_CPY = "waterConnCopy";
  ELECTRICITY_CONN_CPY = "electricityConnCopy";
  ADDRESS_PROOF= "addressProof";
  RESIDENCE_PROOF="residenceProof";
  FORM_AFFIDAVIT = "formAffidavit";
  OTHER_RELATED_DOCS= "otherRelatedDocs";
  enclosures = [
    // {
    //   "title": "1. ಪೂರ್ಣ ಅಂಚೆ ವಿಳಾಸ ನಮೂದಿಸಿರುವ ಅರ್ಜಿ/ Prescribed Application form with Complete Address",
    //   "id": "completeAddress",
    //   "active": false
    // },
    {
      "title": "1.ಮೂಲ ಜಮೀನು ಮಾಲೀಕರಿಂದ ನಿವೇಶನ ಖರೀದಿಗೆ ಸಂಬಂಧಿಸಿದ ಮಾರಾಟ ಪತ್ರಗಳ ಪ್ರಮಾಣೀಕೃತ ಪ್ರತಿ/ Certified copy of the sale deeds regarding purchase of the site from original land owner",
      "id": "saleDeed",
      "active": false
    },
    {
      "title": "2.ನಿವೇಶನ ಖರೀದಿಸಿದ ದಿನಾಂಕದಿಂದ ಈ ತನಕದ ಋಣಭಾರ / ಋಣಭಾರರಾಹಿತ್ಯ ಪತ್ರ (ಇ . ಸಿ) - ನಮೂನೆ - 15 / Encumbrance Certificate in form 15 from the date of purchase of revenue site till date",
      "id": "encumberanceCert",
      "active": false
    },
    {
      "title": "3.ಕಟ್ಟಡದ ಭಾವಚಿತ್ರ/ Photos of the Site with building",
      "id": "buildingSitePic",
      "active": false
    },
    {
      "title": "4.ಪ್ರಸಕ್ತ ವರ್ಷದ ತೆರಿಗೆ ಪಾವತಿಸಿದ ರಸೀದಿಗಳು/ Tax paid receipts of the current year",
      "id": "taxPaidReceipt",
      "active": false
    },
    {
      "title": "5.ಖಾತಾದ ಪ್ರಮಾಣೀಕೃತ ಪ್ರತಿ/ Certified copy of the Khata",
      "id": "khataCopy",
      "active": false
    },
    {
      "title": "6.ಖಾತಾ ಸಾರ/ Khata Extract",
      "id": "khataExtract",
      "active": false
    },
    {
      "title": "7.ನೀರಿನ ಸಂಪರ್ಕಗಳ ಕೆಲಸದ ಆದೇಶದ ಪ್ರತಿ/ Copy of the work order of water connections",
      "id": "waterConnCopy",
      "active": false
    },
    {
      "title": "8.ವಿದ್ಯುತ್ ಸಂಪರ್ಕಗಳ ಕೆಲಸದ ಆದೇಶದ ಪ್ರತಿ/ Copy of the work order of electricity connections9.ವಿದ್ಯುತ್ ಸಂಪರ್ಕಗಳ ಕೆಲಸದ ಆದೇಶದ ಪ್ರತಿ/ Copy of the work order of electricity connections",
      "id": "electricityConnCopy",
      "active": false
    },
    {
      "title": "9.ವಿಳಾಸ ಪುರಾವೆ (ಆಧಾರ್, ಮತದಾರರ ಗುರುತಿನ ಚೀಟಿ, ಪಡಿತರ ಚೀಟಿ ಇತ್ಯಾದಿ)/Address Proof (AADHAAR, Voter ID, Ration card etc)",
      "id": "addressProof",
      "active": false
    },
    {
      "title": "10.ಕಳೆದ 12 ವರ್ಷಗಳಿಂದ ಸದರಿ ಕಟ್ಟಡದಲ್ಲಿ ವಾಸದ ಪುರಾವೆ/ Authorized Proof of residence there for the past 12 years",
      "id": "residenceProof",
      "active": false
    },
    {
      "title": "11.ಅಫಿಡವಿಟ್/ Affidavit",
      "id": "formAffidavit",
      "active":false
    },
    {
      "title": "12.ಇತರೆ ಸಂಬಂಧಿತ ದಾಖಲೆಗಳು / Other Related Documents",
      "id": "otherRelatedDocs",
      "active": false
    }
  ]
  constructor() { }
}
