<div class="mainpage">
    <div fxLayout="row">
        <mat-card *ngIf="data" style="padding-top: 20px !important">

            <div class="col-md-12 col-sm-12 details-area">
                <div class="col-md-2 col-sm-2">
                    <mat-icon (click)="printPage()" class="print-icon">print</mat-icon>
                </div>
                <div class="col-md-10 col-sm-10" style="margin-left: 50%">
                    <button class="btn pre-btn" (click)="onPreviewClick()" style="margin-right: 5px">Preview</button>
                    <button class="btn pre-btn" (click)="goDetailsPage(data)" *ngIf="isEnclosureShown"
                        style="margin-right: 5px">Enclosure</button>
                    <button class="btn pre-btn one-time-editable" (click)="activateOneTimeEdit()"
                        *ngIf="showOneTimeEditButton" style="margin-right: 5px">One-Time Edit</button>
                    <button class="btn pre-btn save-changes" (click)="closeOneTimeEdit()"
                        *ngIf="showSaveChangesBtn">Save Changes</button>
                </div>
            </div>
            <div class="row details-area">
                <div>
                    <label class="info-key">Application ID :</label>
                    <label class="info-value">{{data ? data.applicationNo : ''}}</label>
                </div>
                <div>
                    <label class="info-key">Applicant Name :</label>
                    <label class="info-value">{{data ? data.applicantName : ''}}</label>
                </div>
                <div>
                    <label class="info-key">Mobile No :</label>
                    <label class="info-value">{{data ? data.mobileNumber : ''}}</label>
                </div>
                <div>
                    <label class="info-key">Village :</label>
                    <label class="info-value">{{data ? data.villageName : ''}}</label>
                </div>

                <div>
                    <label class="info-key">Survey No :</label>
                    <label class="info-value">{{data ? data.surveyNumber : ''}}</label>
                </div>
                <!-- <div>
                <label class="info-key">Survey No /Hissa:</label>
                <label class="info-value">{{data ? data.basicDetailsSurveyNoHissa : ''}}</label>
            </div> -->
                <div>
                    <label class="info-key">Site No :</label>
                    <label class="info-value">{{data ? data.siteNo : ''}}</label>
                </div>

            </div>
            <div class="row">
                <div class="checkbox-grid">
                    <div *ngFor="let enclosure of enclosures; let i = index;" class="data-checkbox">
                        <mat-checkbox [checked]="enclosure.link != null" disabled><span class="acko-name">{{i+1}}.
                                {{enclosure.documentName}}</span></mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf=partlyNotifiedSurveyNo>
                <div style="font-size: 18px;font-family:'Times New Roman';"> <b>The said Property is located on
                        Partially Notified Sy.No. Hence field visit is mandated.</b></div>
            </div>
            <div class="row btn-row">
                <div class="col-sm-2 offset-sm-9">
                    <button class="btn btn-next" (click)="onDone()">Done</button>
                </div>
            </div>

        </mat-card>

    </div>
</div>

<!-- section to take print out-->
<div id="print-section" class="printpage" #printData>
    <div class="row" style="padding-bottom: 10pt;border-bottom: solid black 1pt;">
        <div class="col-md-2">
            <img src="../assets/print-logo.jpeg" style="max-width:150px;margin-left: -20%;margin-top: -25%;" />
        </div>
        <div class="col-md-8">
            <style>
                @import url(http://fonts.googleapis.com/earlyaccess/notosanskannada.css);

                body {
                    font-family: "Nirmala UI", sans-serif;
                    font-size: 19.0px;
                    line-height: 1.11em;
                }
            </style>
            <div class="title" style="font-size: 30px;text-align: center;font-family: Tunga;"><b> ಬೆಂಗಳೂರು ಅಭಿವೃದ್ಧಿ
                    ಪ್ರಾಧಿಕಾರ </b></div>
            <div style="font-size: 35px;text-align: center;margin-top: 8%;font-family: 'Times New Roman';">
                <b>Bangalore Development Authority</b>
            </div>
        </div>
    </div>
    <div class="row" style="padding-bottom: 10pt;border-bottom: solid black 1pt;">
        <div class="col-md-12">
            <div class="title"
                style="font-size:24px; text-align: center;margin-top: 1%;font-family:'Times New Roman', Nirmala UI;">
                <b>38(ಡಿ) ಅರ್ಜಿ ಸ್ವೀಕೃತಿ ಪ್ರತಿ</b>
            </div>
        </div>
    </div>
    <br>

    <div
        style="text-align: center; background-color: #3583f6 !important;font: message-box;padding: 14px 40px;border-radius: 12px;
        -webkit-print-color-adjust: exact; color:white !important;margin-inline: 25%;font-size: 22px;font-family:'Times New Roman', Nirmala UI;">
        ಸ್ವೀಕೃತಿ /ACKNOWLEDGEMENT </div>
    <br>
    <div class="row">
        <div class="col-md-6" style="margin-left:25%;">
            <div style="border-style: solid;
                border-color: black;
                border-width: 1pt 1pt 1pt 1pt;
                border-radius: 5pt;padding: 3pt 3pt 3pt 3pt;
                font-size: 20px;font-family:'Times New Roman', Nirmala UI; text-align: center; letter-spacing: 0.6px;"> Application No:&nbsp;&nbsp;<b>{{data ?
                    data.applicationNo : ''}}</b></div><br>
        </div>
        <div class="col-md-6">
            <!-- <div style="border-style: solid;
                border-color: black;
                border-width: 1pt 1pt 1pt 1pt;
                border-radius: 5pt;
                padding: 3pt 3pt 3pt 3pt;
                font-size: 20px;font-family:'Times New Roman', Nirmala UI;"> Registration No. & Application
                No:&nbsp;&nbsp;<b>{{data ? data.applicationNo : ''}}</b></div> -->
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಶ್ರೀಮತಿ/ಶ್ರೀ
                    Smt/Sri:</b>&nbsp;&nbsp;<b>{{data ? data.applicantName : ''}}</b></div>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
        </div>
        <div class="col-md-6">
            <div style="width:100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಗ್ರಾಮ/Village:</b>
                &nbsp;&nbsp;<b>{{data ? data.villageName : ''}}</b></div>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಸರ್ವೆ ನಂ. /Survey
                    No:</b>&nbsp;&nbsp;<b>{{data ? data.surveyNumber : ''}}</b>
                <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಸೈಟ್ ನಂ. /Site
                    No:</b>&nbsp;&nbsp;<b>{{data ? data.siteNo : ''}}</b>
                <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಮೊಬೈಲ್ ಸಂಖ್ಯೆ/ Mobile
                    No:</b>&nbsp;&nbsp;<b>{{data ? data.mobileNumber : ''}}</b>
                <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top:18px;font-size: 15px;font-family:'Times New Roman', Nirmala UI;">
        <div style="width:100%;margin-left: 2%;">
            ರವರಿಂದ ಭರ್ತಿಮಾಡಿದ ಅರ್ಜಿಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ. / Received filled Application.
        </div>
    </div>
    <div class="row" style="margin-top:50px; font-size: 18px;font-family:'Times New Roman', Nirmala UI;">
        <div style="text-align: center;width:100%;color:black;"><b>ಸ್ವೀಕೃತ ದಾಖಲೆಗಳು /Received Enclosures</b>
            <div style="border-bottom: solid;color: black;
            border-top: outset;margin-inline: 23%;border-width: medium;"></div>
        </div>
    </div>
    <br>
    <div>
        <div class="col-md-12" style="border-style: ridge;
            border-color: black;
            border-width: 1pt 2pt 1pt 2pt;
            border-radius: 0pt; padding: 2pt 2pt 2pt 2pt; padding-top: 20px;">
            <div *ngFor="let enclosure of enclosuresPrintData; let i = index;"
                style="font-size: 18px;font-family:'Times New Roman', Nirmala UI;">
                <div *ngIf="enclosure.active">
                    <input type="checkbox" style="margin-top:-18px;" checked="true"><span>{{enclosure.title}}</span>
                </div>
                <div *ngIf="!enclosure.active">
                    <mat-checkbox style="margin-right:2px;"></mat-checkbox> <span>{{enclosure.title}}</span>
                </div>
                <br>
            </div>
        </div>
    </div>

    <br>
    <div *ngIf=partlyNotifiedSurveyNo>
        <div style="font-size: 20px;font-family:'Times New Roman';"><b>The said Property is located on Partially
                Notified Sy.No. Hence field visit is mandated.</b> </div>
    </div>

    <div style="margin-top: 40px; padding-left: 13px; height: 120px;
    border-style: ridge;
    border-color: rgb(56, 56, 56);
    border-width: 1pt 2pt 1pt 2pt;
    border-radius: 0pt; padding: 2pt 2pt 2pt 2pt; font-size: 20px;font-family:'Times New Roman';">
        <div style="font-family:'Times New Roman'; padding-top: 60px; margin-left: 2%">Receiving Date:</div>
        <div style="margin-left: 50%; margin-top: -2%;font-family:'Times New Roman';">Signature of Receiving Authority
            with seal</div>
    </div>
</div>
