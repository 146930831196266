import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-online-application-login',
  templateUrl: './online-application-login.component.html',
  styleUrls: ['./online-application-login.component.scss']
})
export class OnlineApplicationLoginComponent implements OnInit {

  email : string;
  otp : number;
  showOtpScreen = false;
  isLoading = false;
  

  constructor(private authService: AuthService, private jccEncodeDecoder: JccnoEncodeDecodeService,
     private _snackBar: MatSnackBar, private userService: UserService, private router : Router, 
     private roleConfig: RoleConfigService) { }

  ngOnInit(): void {
   
  }

  sendOtp(){
    if(!this.email){
      this.openSnackbar('Email field must not be empty', 'Dismiss');
      return;
    }
    this.isLoading = true;
    let payload = {};
    payload['email'] = this.email;
      this.authService.onlineApplicationGenerateOtp(payload).subscribe(res =>{
          this.showOtpScreen = true;
          this.isLoading = false;
      })
  }

  submitOtp(){
    if(!this.email || !this.otp){
      return;
    }
    this.isLoading = true;
    let payload = {};
    payload['email'] = this.email;
    payload['password'] = this.otp.toString();
    payload['loginType'] = this.roleConfig.ROLE_ONLINE_APPLICATION_USER;
    this.authService.login(payload, this.loginResHandler);
  }

  loginResHandler = (res: any): void => {
    if(res && res.success) {
      this.fetchSavedApplication();
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  fetchSavedApplication(){
     this.authService.fetchOnlineApplicantNo().subscribe(res =>{
       console.log('res ',res)
      this.openSnackbar('Login successful', 'Dismiss');
       if(!res){
        return this.router.navigate(['/application_login']);    
       }
       let docodeNo = this.jccEncodeDecoder.encode(res.applicationNo)
       return  this.router.navigate(['/application_login'], { queryParams: { applicationNo: docodeNo } });
     })
  }

  changeEmail(){
    this.showOtpScreen = false;
  }

  resendOTP(){
    this.sendOtp();
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  ngOnDestroy() {
    
  }
}
