<div fxFlex="100%">
        <div fxFlex="25" class="side-menu-container">
            <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
        </div>

        <div fxFlex="100" style="padding-left: 55px">

                <div class="row application-form-row">
                    <label class="form-label">1. ಅರ್ಜಿದಾರನ ಹೆಸರು /Name of the applicant  : <span class="text-danger"> * </span></label>
                    <input type="text" [(ngModel)]="applicantName" class="form-textbox" [readonly]="(data && data.applicantName)" />
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div style="width: 120%"  class="row application-form-row">
                        <label class="form-label">2. ಅರ್ಜಿದಾರರ ವಯಸ್ಸು/ Age <span class="text-danger"> * </span></label>
                    <input type="number" [(ngModel)]="age" class="form-textbox" [readonly] = "(data && data.applicantAge)" onKeyPress="if(this.value.length==2) return false;"/>
                        <!-- <select class="form-textbox" [(ngModel)] = "age" >
                            <option >Select AGE</option>
                            <option *ngFor = "let age of ages" [value]="age.age">{{age.age}} </option>
                        </select> -->
                    </div>
                </div>

            <div class="row application-form-row ">
                    <label class="form-label">3. ತಂದೆ/ಗಂಡನ ಹೆಸರು/Father’s/Husband’s Name: <span class="text-danger"> * </span></label>
                    <input type="text" [(ngModel)]="fatherName" class="form-textbox" [readonly] = "(data && data.fatherName)" />
            </div>

             <div class="row application-form-row ">
                <label class="form-label">4. ಉದ್ಯೋಗ/ Occupation:</label>
                <input type="text" [(ngModel)]="occupation" class="form-textbox" [readonly] = "(data && data.occupation) && !isEditable" />
        </div>


            <div class="row application-form-row ">
                    <label class="form-label">5. ಮೊಬೈಲ್ ಸಂಖ್ಯೆ / Mobile No (10 digits Only): <span class="text-danger"> * </span></label>
                    <input type="number" [(ngModel)]="mobileNumber" class="form-textbox" [readonly] = "(data && data.mobileNumber)" maxlength="10" onKeyPress="if(this.value.length==10) return false;"/>
            </div>

            <div  class="row application-form-row " fxLayout="row"fxLayoutAlign="space-between">
                <label class="form-label">6. ಅರ್ಜಿದಾರರ ಪ್ರಸ್ತುತ ವಿಳಾಸ/Present Address of the Applicant  :
                    <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="presentAddress" class="form-textbox" [readonly] = "(data && data.applicantAddress) && !isEditable"/>
              </div>
              <div class="row application-form-row ">
                <label class="form-label">7. ಆಧಾರ್ ಸಂಖ್ಯೆ / AADHAAR No (12 digits Only): <span class="text-danger">* </span></label>
                <input type="number" [(ngModel)]="aadharNo" class="form-textbox" [readonly] = "(data && data.aadharNo) && !isEditable" maxlength="12" onKeyPress="if(this.value.length==12) return false;"/>
            </div>


                        <div fxLayout="row" style="margin-top: 20px">
                            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
                        </div>
