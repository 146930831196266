import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';

@Component({
  selector: 'app-court-details',
  templateUrl: './court-details.component.html',
  styleUrls: ['./court-details.component.scss']
})
export class CourtDetailsComponent implements OnInit {

  public pageName: string = "court-details";

  isLand: any;
  courtName: any;
  hasCasePending: any = null;
  caseNo: any;
  public isEditable: boolean = false;
  private applicantId: any;
  public data : any;
  @Output() newApplicantCallback = new EventEmitter<any>();
  
  constructor(private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar, 
    private route: ActivatedRoute, 
    private jccEncodeDecoder: JccnoEncodeDecodeService) { }

  ngOnInit(): void {
    this.fetchApplication();
  }
  reset(){
    this.caseNo = null;
    this.hasCasePending = null;
    this.courtName = null;
   
  }

  private fetchApplication = () => {
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
      this.updateUI(res);
    })
  }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    this.setValue(res);
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("basic personal details", res);
        this.updateUI(res);
    })
   }

   setValue(data: any): void{
    console.log('data ', data)
    this.courtName = data.courtName;
    this.hasCasePending = data.hasCasePending;
    this.caseNo = data.caseNo;
  }

   onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.applicantId) {
            this.updateApplicantCourtDetailsApiCall(this.applicantId);
            return;
          }
          this.updateApplicantCourtDetailsApiCall(this.applicantId);
        }
    })
  }

  validateAndConstructPayload = () => {
    if(this.hasCasePending == null) {
      throw new Error("Choose whether you have case pending or not");
    }
    if(this.hasCasePending){
      if(this.caseNo == null){
        throw new Error("Please enter the case number");
      }
      if(this.courtName == null){
        throw new Error("Please enter the court name")
      }
    }
    let payload = {};
    payload['hasCasePending'] = this.hasCasePending;
    payload['caseNo'] = undefined;
    payload['courtName'] = undefined;

    // only if yes selected
    if(this.hasCasePending) {
      payload['caseNo'] = this.caseNo;
      payload['courtName'] = this.courtName;
    }
    return payload;
  }

  private getApplicant(res: any): void {
    this.applicationService.fetchApplicantById(res.id).subscribe(res => {
      this.updateUI(res);
    }, err => {
      console.log(err);
      this.openSnackbar(err?.message, "Dismiss");
    })
  }

  updateApplicantCourtDetailsApiCall = (applicantId: any) => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.updateCourtDetails(applicantId, payload).subscribe(res => {
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.getApplicant(res);
        this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  saveApplicantBasicDetailsApiCall = () => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res =>{
        window.localStorage.setItem("applicantId", res.id);
        this.applicantId = res.id; // same time they can fill missing field without moving next screen
        this.data = res; // for one time edit
        this.setEditable(res);
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.newApplicantCallback.emit(res);
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  updateApplicantBasicDetailsApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveSiteDetails(applicantId, payload).subscribe(res =>{
        this.data = res; // for one time edit
        this.setEditable(res);
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.newApplicantCallback.emit(res);
      }, err => {
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }
}
