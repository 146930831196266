<div class="label"  *ngIf = "data">
      
    <div class ="d-flex align-items-center justify-content-center text-title"  >APPLICATION NO:{{data.applicationNo}} </div>
    <div >
        <button (click)="clickBack()"class="back-link">Back</button>
     </div>
   <div>
    <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
</div> 
<br>
<br>
<br>
<div class="sub-title">
    Basic Info
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Registration & Application No</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.applicationNo}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Date Of Registration</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.createdAt}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Name Of The Applicant</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.applicantName}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Father’s/Husband’s Name</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.fatherName}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Occupation</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.occupation}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Mobile Number</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.mobileNumber}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Aadhar Number</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.aadharNo}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Present Address of the Applicant</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.applicantAddress}}</label>
    </div>
</div>
<div class="sub-title">
    Property Details
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Name of the Owner of the site with Building</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.buildingOwnerName}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Site No</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.siteNo}}</label>
    </div>
</div>

<div class="row">
    <div class="col-sm-8 ">
        <label>Extent of Site</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.extentSite}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Date of Purchase</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.dateOfPurchase}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Village name</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.villageName}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Survey No</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.surveyNumber}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Date of Construction of the Building</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.buildingConstructionDate}}{{data.buildingConstructionYear}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Type of Building</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.buildingType}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Usage</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.buildingUsage}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Constructed with approval?</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.isPlanApproved}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Particulars of Unauthorized Construction sought to be Regularized/Re-conveyed</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.unauthorizedConstruction}}</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-8 ">
        <label>Area/Layout</label>
    </div>
    <div class="col-sm-4">
        <label>{{data.areaLayout}}</label>
    </div>
</div>