import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StrorageService {

  localStorage: Storage;

  private ACCESS_TOKEN_KEY: string = "access_token_key_";

  private USER_KEY: string = "user";

  private SELECTED_VILLAGE_KEY: string = "selected_village";

  constructor() {
    this.localStorage = window.localStorage;
  }

  saveUser = (user: any) => {
    this.localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    this.saveAccessToken(user['accessToken']);
  }

  getUser = () => {
    return JSON.parse(this.localStorage.getItem(this.USER_KEY));
  }

  saveAccessToken = (access_token: any) => {
    this.localStorage.setItem(this.ACCESS_TOKEN_KEY, access_token);
  }

  getAccessToken = () : string => {
    return this.localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  clear = () => {
    this.localStorage.clear();
  }

  getItem(key): any {
    if (this.isLocalStorageSupported) {
      return this.localStorage.getItem(key);
    }
    return null;
  }

  setItem(value: any, key): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, value);
      return true;
    }
    return false;
  }

  removeItem(key): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  isAlreadyLogin(): boolean {
    return this.localStorage.getItem(this.USER_KEY) ? true : false;
  }
  
  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }

}
