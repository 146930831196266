import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { CourtDetailsComponent } from '../court-details/court-details.component';

// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'YYYY',
//   },
//   display: {
//     dateInput: 'YYYY',
//     monthYearLabel: 'YYYY',
//     monthYearA11yLabel: 'YYYY',
//   },
// };

interface Year {
  year: string;
  years: string;
}

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
  // providers: [ { provide: DateAdapter, useClass: MomentDateAdapter, 
  // deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  // {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  // ]
})


export class PropertyDetailsComponent implements OnInit {

  @ViewChild(CourtDetailsComponent) courtDetailsComponent;


  public data: any;

  public isEditable: any;


  public menus: any = [
    {
      "name": "Property Details",
      "code": "property_details",
      "active": true,
      "default": true,
      "sub_menus": []
    },
    {
      "name": "Court Details",
      "code": "court_details",
      "active": true,
      "default": true,
      "sub_menus": []
    },

  ];
  

  public applicantName: string;

  public telephoneNumber: number;

  public mobileNumber: number;

  public email: string;

  public surveyNumber: string;

  public aadharNumber: number;

  public age: number;

  public villages: any[];

  public buildingTypes: any[];

  public selectedVillage;

  public applicantAddress: string;

  public selectedMenu: any = {};

  private applicantId: any;

  public maxDate = new Date().getTime();

  public DateOfBirth: Date;

  public dateOfPurchase: Date;

  public isPlanApproved: string;

  public buildingConstructionDate: Date;

  public registrationSaleDeed: Date;

  public buildingOwnerName: string;

  public extentSite: any;

  public villageName: any;

  public buildingType: any;

  public unauthorizedConstruction: string;

  public areaLayout: string;

  public buildingUsage: string;

  public Gender: string;

  public yes: boolean;

  public no: boolean;

  public Married: string;

  public Unmarried: string;

  public cameraChoosen = false;

  public selectedFile;

  public selectedFileName;

  public profileImg: any;

  faTrash = faTrash;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();



  yearOfAcquisition: any;
  public siteNo: any;
  nameoftheVillage: any;
  nameoftheScheme: any;
  compensationReceived: any;
  year: any;
  furnishLACNo: any;
  ddNo: any;
  lacNo: any;
  buildingConstructionYear: any;
  isLand: string;
  acquisitionYear: any;
  schemeName: any;
  saledeedRegDate: any;
  propertyDetailsSiteDimension: any;
  propertyDetailsSiteNo: any;
  isKhataIssued: any;
  isKhataIssuedByPanchayat: any;
  panchayats: any[];
  public surveys: any[];
  public acquiredPropSurveyNo: any;
  public selectedPanchayat: any;
  surveyNoHissa: any;

  constructor(private applicationService: ApplicationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService) { }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.fetchApplication();
  }

  // chosenYearHandler(ev, input){
  //   let { _d } = ev;
  //   this.buildingConstructionDate = _d;
  //   input._destroyPopup()
  // }


  getAllVillages() {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villages = res;
      // this.villageId=this.villages.indexOf(this.selectedVillage)
      console.log(this.villages);
      this.fetchApplication();
      //this.villageId=res.id;
    }, err => {
      console.log("personal details component getVillages() err", err);
    }, () => {
      console.log("personal details component getVillages() completed");
    })
  }

  onChangeEvent(event){
    console.log(event);
    console.log(event.value);
  }


  getAllBuildingTypes() {
    this.applicationService.fetchAllBuildingTypes().subscribe(res => {
      this.buildingTypes = res;
      console.log(this.buildingTypes);
      this.fetchApplication();
    }, err => {
      console.log("property details component getBuildingTypes() err", err);
    }, () => {
      console.log("property details component getBuildingTypes() completed");
    })
  }

  private fetchApplication = () => {
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if (encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if (!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res => {
      this.applicantId = res.id;
      this.setEditable(res);
      this.data = res;
      this.updateUI(res);
    })
  }

  onVillageChanged = (villageId: any, data?: any) => {
    this.applicationService.fetchSurveyNoByVillageId(villageId).subscribe(res => {
      this.surveys = res;
      if(data && data.surveyMapperVO) {
        this.surveyNumber = data.surveyMapperVO.id;
      }
    })
  }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    this.setValue(res);
    this.newApplicantCallback.emit(res);
    //this.onVillageChanged(res.siteDetailsVillageVO.id, res);
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res => {
      console.log("basic personal details", res);
      this.updateUI(res);
    })
  }

  clearDate() {
    this.buildingConstructionDate = null;
  }

  clearYear() {
    this.buildingConstructionYear = null;
  }

  reset() {
    this.yearOfAcquisition = null;
    this.surveyNumber = null;
    this.nameoftheVillage = null;
    this.nameoftheScheme = null;
    this.compensationReceived = null;
    this.year = null;
    this.furnishLACNo = null;
    this.ddNo = null;
    this.lacNo = null;
    this.isKhataIssued = null;
  }

  validateAndConstructPayload = () => {

    let payload = {};


    if (!this.buildingOwnerName) {
      throw new Error('Building owner name field must not be empty');
    }
    if (!this.siteNo) {
      throw new Error('Site No field must not be empty');
    }
    if (!this.extentSite) {
      throw new Error('Extent of Site field must not be empty');
    }
    if (!this.dateOfPurchase) {
      throw new Error('Date of purchase field must not be empty');
    }
    if (!this.isPlanApproved) {
      throw new Error('Please select if plan is approved');
    }
    if (!this.buildingConstructionDate && !this.buildingConstructionYear) {
      throw new Error('Building construction Date/Year field must not be empty');
    }
    if (this.buildingConstructionDate && this.buildingConstructionYear) {
      throw new Error('Please enter only construction Date or Year');
    }
    if (!this.buildingType) {
      throw new Error('Building Type field must not be empty');
    }
    if (!this.buildingUsage){
      throw new Error('Usage field must not be empty')
    }
    

    payload['buildingOwnerName'] = this.buildingOwnerName;
    payload['siteNo'] = this.siteNo;
    payload['extentSite'] = this.extentSite;
    payload['dateOfPurchase'] = this.dateOfPurchase;
    payload['villageName'] = this.villageName;
    payload['surveyNumber'] = this.surveyNumber;
    payload['buildingConstructionDate'] = this.buildingConstructionDate;
    payload['buildingConstructionYear'] = this.buildingConstructionYear;
    payload['buildingType'] = this.buildingType;
    payload['isPlanApproved'] = this.isPlanApproved;
    payload['unauthorizedConstruction'] = this.unauthorizedConstruction;
    payload['areaLayout'] = this.areaLayout;
    payload['buildingUsage'] = this.buildingUsage;

    return payload;
  }

  setValue(data: any): void {
    console.log('data ', data);
    this.buildingConstructionDate = data.buildingConstructionDate;
    this.siteNo = data.siteNo;
    this.unauthorizedConstruction = data.unauthorizedConstruction;
    this.buildingOwnerName = data.buildingOwnerName;
    this.surveyNumber=data.surveyNumber;
    this.villageName=data.villageName;
    this.areaLayout=data.areaLayout;
    this.buildingConstructionYear=data.buildingConstructionYear;
    this.isPlanApproved = data.isPlanApproved;
    this.extentSite = data.extentSite;
    this.saledeedRegDate = data.saledeedRegDate;
    this.dateOfPurchase = data.dateOfPurchase;
    this.buildingType = data.buildingType;
    this.buildingUsage = data.buildingUsage;
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (this.applicantId) {
          this.updateApplicantPropertyDetailsApiCall(this.applicantId);
          return;
        }
        this.updateApplicantPropertyDetailsApiCall(this.applicantId);
      }
    })
  }



  private setEditable = (data: any): void => {
    if (data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  // allow || restrict to only spec chars and numbers in textbox

  // omit_special_char(event) {
  //   var k;
  //   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
  //   return (k == 8 || k == 32 || (k >= 33 && k <= 47) || (k >= 48 && k <= 57));
  // }


  private getApplicant(res: any): void {
    this.applicationService.fetchApplicantById(res.id).subscribe(res => {
      this.updateUI(res);
    }, err => {
      console.log(err);
      this.openSnackbar(err?.message, "Dismiss");
    })
  }

  updateApplicantPropertyDetailsApiCall = (applicantId: any) => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.updatePropertyDetails(applicantId, payload).subscribe(res => {
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.getApplicant(res);
        this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }
}
