import { Component, Input, OnInit } from '@angular/core';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
   name: string; 
  color: string;
  loginPath: string;
  disabled?: boolean = false;
  isUserLoggedIn : any;
  style: string;

 public newsContent: any[] = [];

 public profileContent: any[] = [];

 public shownNewsNumber = 1;

 public enableHelpDeskUser: any;

 public enableScrutinyUser: any;

 public  enableFieldUser: any;

 public enableOnlineApplicationUser: any;

 public enableComitteeUser: any;

 clicked=true;


  constructor(private storageService:StrorageService, 
    private router: Router ,
    private roleConfigService: RoleConfigService,
    private authService: AuthService, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,
    private applicationService: ApplicationService,
     private userService: UserService,
     ) { }

  ngOnInit(): void {
    this.addNewsPaperContent();
    this.addProfileContent();
    this.redirectHomeScreen();
  }

  private checkLoginSettings(){
    this.applicationService.getLoginSettings().subscribe(res=> {
      for(let i = 0;i < res.length;i++) {
          let loginSettings = res[i];
          if(loginSettings['loginUserType'] == 'help_desk_user') {
            this.enableHelpDeskUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'scrutiny_user') {
            this.enableScrutinyUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'field_visit_user') {
            this.enableFieldUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'online_application_user') {
            this.enableOnlineApplicationUser = loginSettings['enabled'];
          }
          if(loginSettings['loginUserType'] == 'comittee_user') {
            this.enableComitteeUser = loginSettings['enabled'];
          }
      }
    })
    console.log(this.enableHelpDeskUser+" and "+this.enableScrutinyUser);
  }

  redirectHomeScreen(): void {
    if(!this.storageService.isAlreadyLogin()){
      this.checkLoginSettings();
      return;
    }
    let user = this.storageService.getUser();
    let roleCode = user['roleCode'];
    if(roleCode == this.roleConfigService.ROLE_HELP_DESK_USER) {
      this.router.navigate(['/application']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_USER) {
      this.router.navigate(['/scrutiny-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_ADMIN) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_COMMITTEE) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_FIELD_USER) {
      this.router.navigate(['/field-viisit-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_ONLINE_APPLICATION_USER) {
      this.fetchOnlineSavedApplication();
    }
  }

  fetchOnlineSavedApplication(){
    this.authService.fetchOnlineApplicantNo().subscribe(res =>{
      if(!res){
        this.router.navigate(['/application_login']);    
      } else {
        let docodeNo = this.jccEncodeDecoder.encode(res.applicationNo)
        this.router.navigate(['/application_login'], { queryParams: { applicationNo: docodeNo } });
      }
    })
 }

  onPrevNewsClick(){
    if(this.shownNewsNumber == 1){
      return;
    }
    this.shownNewsNumber--;
  }

  onNextNewsClick(){
    if(this.shownNewsNumber == this.newsContent.length-2){
      return;
    }
    this.shownNewsNumber++;
  }
  onCommitteeClick(){
    // if(this.disabled) {
    //   return;
    // }
    this.router.navigate(['/committee-login']);
  }
  onHelpDeskClick(){
    this.router.navigate(['/helpdesk-login']);
}
onScrutinyClick(){
 this.router.navigate(['/scrutiny-login']);
 
}
onApplicantClick(){
  this.router.navigate(['/online-applicant-login']);
 
}
onFieldVisitClick(){
 
 // this.router.navigate(['/field-visit-login']);
 
}


  isHelpdeskUser(){
     this.router.navigate([this.loginPath]);
    return this.userService.getUserType() == this.userService.HELPDESK_USER;

  }

  isOnlineApplicationUser(){
    return this.userService.getUserType() == this.userService.ONLINE_APPLICATION_USER;
  }

  isCommitteeUser(){
    
    return this.userService.getUserType() == this.userService.COMITTEE_USER;
  }

  isScrutinyUser(){
    return this.userService.getUserType() == this.userService.SCRUTINY_USER;
  }
  isFieldUser(){
    return this.userService.getUserType() == this.userService.FIELD_VISIT_USER;
  }
  addStyle(){
    this.style='font-size:20px';
  }

  addNewsPaperContent(){

      let news1 = {};
      news1['date'] = "Dec 3, 2020";
      news1['title'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";
      news1['news'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";

      let news2 = {};
      news2['date'] = "Jan 16, 2021";
      news2['title'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";
      news2['news'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";

     let news3 = {};
      news3['date'] = "Jan 20, 2021";
      news3['title'] = "The committee decides to use law students from CRM Law College, University \
                       in Banglore India, as Interms for their work at 17 villages ";
      news3['news'] = "The committee decides to use law students from CRM Law College, University in \
                       Banglore India, as Interms for their work at 17 villages ";

      let news4 = {};
      news4['date'] = "Jan 31, 2021";
      news4['title'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";
      news4['news'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";

  

      this.newsContent.push(news1);
      this.newsContent.push(news2);
      this.newsContent.push(news3);
      this.newsContent.push(news4);
   
  }

  addProfileContent(){

    let profile1 = {};
    profile1['name'] = "" ; 
    profile1['imagePath'] = "";
    profile1['content'] = " ";

    let profile2 = {};
    profile2['name'] = "" ; 
    profile2['imagePath'] = "";
    profile2['content'] = " ";

    let profile3 = {};
    profile3['name'] = "" ; 
    profile3['imagePath'] = "";
    profile3['content'] = "";

    this.profileContent.push(profile2);
    this.profileContent.push(profile3);
    this.profileContent.push(profile1);
 
  }
}
