import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ErrorDialogComponent } from '../shared/error/error-dialog/error-dialog.component';


@Component({
  selector: 'app-upload-enclosure',
  templateUrl: './upload-enclosure.component.html',
  styleUrls: ['./upload-enclosure.component.scss']
})
export class UploadEnclosureComponent implements OnInit {

  private applicantId: any;

  public faCheck = faCheck;

  public faTimes = faTimes;

  public faTrash = faTrash;

  public isEditable: any;

  public files: any[];

  public isLoading = false;

  saleDeed: any;

  addressProof: any;

  public data: any;

  public enclosure: any;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();

  constructor(private applicationService: ApplicationService, private route: ActivatedRoute,
    private jccEncodeDecoder: JccnoEncodeDecodeService, private router: Router, private _snackBar: MatSnackBar, @Inject(DOCUMENT) private document) {
    this.files = [];
  }

  ngOnInit(): void {
    console.log("enclosure form");
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if (encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplication = () => {
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if (encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    this.setValue(res);
    this.newApplicantCallback.emit(res);
    //this.onVillageChanged(res.siteDetailsVillageVO.id, res);
  }

  private setEditable = (data: any): void => {
    if (data.oneTimeEdit != null) {
      this.isEditable = data.oneTimeEdit;
    }
  }

    setValue(data: any): void {
    console.log('data ', data);
    this.enclosure = data.enclosure;
  }

  public fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res => {
      console.log("upload enclosure form details", res);
      this.applicantId = res.id;
      this.setEditable(res);
      this.data = res;
      this.enclosure = res.enclosure;
      console.log("enclosure:" + this.enclosure)
    })
  }

  private getApplicant(res: any): void {
    this.applicationService.fetchApplicantById(res.id).subscribe(res => {
      this.updateUI(res);
    }, err => {
      console.log(err);
      this.openSnackbar(err?.message, "Dismiss");
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  onFileChanged = (event: any) => {
    let idx = -1;
    for (var i = 0; i < this.files.length; i++) {
      if (this.files[i].name == event.target.name) {
        idx = i;
        break;
      }
    }
    if (idx >= 0) {
      this.files[idx].data = event.target.files[0];
    } else {
      const file = {};
      file['name'] = event.target.name;
      file['data'] = event.target.files[0];
      this.files.push(file);
    }
  }

  public onSubmit = (): void => {
    if (!this.applicantId) {
      this.openSnackbar("Application not created. please fill Basic Details Form");
      return;
    }
    if (this.files.length == 0) {
      alert("Please select atleast one enclosure");
      return;
    }
    if (this.data.enclosure) {
      if (!this.enclosure.saleDeed) {
        this.openSnackbar("Sale Deed is required, upload and try again.");
        return;
      }
    } else {
      if (!this.saleDeed) {
        this.openSnackbar("Sale Deed is required, upload and try again.");
        return;
      }
    }

    if (this.data.enclosure) {
      if (!this.enclosure.addressProof ) {
        this.openSnackbar("Address proof is required, upload and try again.");
        return;
      }
    } else {
      if (!this.addressProof) {
        this.openSnackbar("Address Proof is required, upload and try again.");
        return;
      }
    }
    // if (!this.saleDeed || !this.addressProof) {
    //   this.openSnackbar("Please check for mandatory documents and try again.");
    //   return;
    // }
    const formData = new FormData();
    for (const file of this.files) {
      formData.append(file.name, file.data, file.data.name);
    }
    this.applicationService.uploadDocuments(this.applicantId, formData).subscribe(res => {
      this.data = res;
      this.enclosure = res.enclosure;
      this.getApplicant(res);
      this.openSnackbar("Successfully Uploaded");
    })
    return;
  }

  private fetchApplicantById = (applicantId: any) => {
    if (!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res => {
      this.data = res;
      this.enclosure = res.enclosure;
      this.updateUI(res);
      this.setEditable(res);
    })
  }

  dismissIconClick = (id: any) => {
    (<HTMLInputElement>this.document.getElementById(id)).value = null;
    if (this.files.length > 13 || this.files.length < 0) {
      return;
    }
    this.files.map((row, index) => {
      if (row.name == id) {
        this.files.splice(index, 1);
      }
    })
  }
}
