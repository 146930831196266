import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { CommentDialogService } from './comment-dialog/services/comment-dialog.service';

@Component({
  selector: 'app-scrutiny-preview',
  templateUrl: './scrutiny-preview.component.html',
  styleUrls: ['./scrutiny-preview.component.scss']
})
export class ScrutinyPreviewComponent implements OnInit {
  
public application_id : any;
applicationData: any;
selectedPanchayat = '';
message:string;
comment:string;
public faTimes = faTimes;
public isLoading=false;
selectedVillages: any;
public commentTypes: any = [];
  public title: any;
  approved_filter: any;
 
  
  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private commentDialogService: CommentDialogService ) { 
    this.application_id = this.route.snapshot.queryParams['application_id'];
    this.title=this.route.snapshot.queryParams['title'];
    console.log("title", this.title);
    console.log("application no", this.application_id);
  }

  ngOnInit(): void {
    this._fetchDataByApplicationNo();
  }

  private _fetchDataByApplicationNo() {
    this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
      console.log("app",res)
      this.applicationData = res;
      this.fetchAllPanchayats();
      this.fetchAllCommentTypes();
     })
  }

  close() {
    this.selectedVillages=this.route.snapshot.queryParams['village'];
    this.router.navigate(['/scrutiny-user-dashboard']);
     
  }
  cancelClick(){
    this.close();
  }

  private fetchAllPanchayats(){
    this.applicationService.fetchAllPanchayats().subscribe(res=>{
      if(this.applicationData &&  this.applicationData.panchayat && res){
        this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
      }
    })
  }

  private fetchAllCommentTypes(){
    this.applicationService.getScrutinyUserCommentTypeRef().subscribe(res=>{
      this.commentTypes = res.data;
      let selectedCommentTypes = this.applicationData.scrutinyUserCommentTypes;
      for(let i = 0;i < this.commentTypes.length;i++) {
        let isChecked = false;
        for(let j = 0;j < selectedCommentTypes.length;j++) {
            if(this.commentTypes[i]['messageId'] == selectedCommentTypes[j]['messageId']) {
                isChecked = true;
                break;
            }
        }
        this.commentTypes[i].checked = isChecked;
      }
    })
  }

  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
  }

  clickBack(){
    window.history.back();
  }

  updateScrutinyDetails = (status: any) => {
    let payload = {};
    payload['status_code'] = status;
    payload['comment'] = this.comment;
    payload['applicantScrutinyMessages'] = [];
    for(let i = 0;i < this.commentTypes.length;i++) {
      if(this.commentTypes[i].checked) {
        payload['applicantScrutinyMessages'].push(this.commentTypes[i]);
        
      }
    }
      let options = {};
      if(status == 'referred_field_visit') {
        options['message'] = 'Application Referred to Field visit.';
      } else if(status == 'scrutinized_referred_committee'){
        options['message'] = 'Application Scrutinized and Furthered for the Commitee.';
    
      }if(status == 'further_investigation_required'){
        options['message'] = 'Application marked to Further Investigation';
      }
      options['title'] = 'Confirm',
      options['cancelText'] = 'CANCEL',
      options['confirmText'] = 'OK'

      this.confirmDialogService.openDialog(options);
      this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          this.applicationService.updateScrutinyStatus(this.application_id,payload).subscribe(res => {
            this.clickBack();
          })
        }
      });
  }
  checkValue($event:any){
       this.message
  }
}
