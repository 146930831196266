<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        
    </div>
    <div class="row tool-bar">
        <div class="col-sm-1 offset-sm-1">
            <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon"   [spin]="isRefreshing"></fa-icon>
            </button>
         
                </div>
                <div class="col-sm-3 offset-sm-6 user-details">
                    <div class="value-text">
                        Welcome 
                    </div>
                    <div class="value-text">
                         {{userName}} !
                    </div>
                    </div>
    </div>
 
        
    <div class="col-sm-10 offset-sm-1">
        <h2 class="statics-header">Key Metrics</h2>
            <app-statics (staticsClick)="onStaticsClick()"></app-statics>
        </div>
    
        <div class="col-sm-10 offset-sm-1">               
        <h2 class="statics-header">Application Statistics</h2>
                <!-- <app-application-statics></app-application-statics> -->
                <app-application-bar-chart></app-application-bar-chart>
                <h2 class="statics-header">Audit Helpdesk Statistics</h2>
                <app-audit-helpdesk-chart></app-audit-helpdesk-chart>
            </div>


    <div class="row">
        <div class="col-sm-10 offset-sm-1" [hidden]="!panchayats || panchayats.length == 0">
            <div class="panchayat-header">
                Choose Panchayat 
            </div>
            <div class="panchayat-options">
                <app-filter-option *ngFor="let panchayat of panchayats; let i = index;"
                 [data]="panchayat" [selectedId]="selectedPanchayat" [color]="colors[i]" (selectOption)="onPanchayatChange($event)" type="panchayat" 
                [hidden]="!panchayat || !panchayat.name"></app-filter-option>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-10 offset-sm-1" [hidden]="!selectedPanchayat">
            <div class="panchayat-header">
            {{this.isCommitteeUser ? 'Villages' : ' Choose Village'}}
            </div>
            <div class="village-options">
                <app-filter-option *ngFor="let village of villages; let i = index;"
                 [data]="village" [color]="colors[i]" [selectedId]="selectVillage" (selectOption)="onVillageChange($event)" type="village"
                ></app-filter-option>
            </div>
        </div>
    </div>

 

    <div class="col-md-8 offset-md-2" [hidden]="!selectVillage && isCommitteeUser">
        <!-- <div class="col-md-8 offset-md-2" [hidden]="!selectVillage || isCommitteeUser"> -->

        <!-- data table -->
        <mat-card>
        
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="sn">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}</td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="applicationNo">
                <th mat-header-cell *matHeaderCellDef> Application No. </th>
                <td mat-cell *matCellDef="let element">
                <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationId}}</a>
            </td>
            </ng-container>
            
            <!-- Name Column -->
            <!-- <ng-container matColumnDef="applicantName">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
            </ng-container> -->
            
            <!-- Telephone Column -->
            <!-- <ng-container matColumnDef="telephoneNumber">
                <th mat-header-cell *matHeaderCellDef> Mobile </th>
                <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
            </ng-container> -->

              <!-- Preview Column -->
              <!-- <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="onPreviewClick(element)" class="preview-link">Preview</a> <span class="vertical-divider">|</span>
                    <a (click)="goDetailsPage(element)" class="enclosure-link">Enclosure</a> 
            </ng-container> -->
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource || dataSource.data.length <= 0">No Records Found!</div>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
           [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
       </mat-paginator>
    </mat-card>
    </div>

    <div class="spinner" *ngIf="isLoading">
        <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
        </div>
    <app-footer></app-footer>
<!-- </div> -->
</div>