<div class="row content-area">
    <div class="title">
        <span>ಸೈಟ್ ಅಥವಾ ಕಟ್ಟಡದ ಉಲ್ಲೇಖದೊಂದಿಗೆ, ಯಾವುದೇ ನ್ಯಾಯಾಲಯದಲ್ಲಿ ಯಾವುದೇ ಪ್ರಕರಣ ಬಾಕಿ ಇದೆಯೇ?</span>
        <span>With reference to the site or building, is there any pending case in any court?</span><span class="text-danger"> *
        </span>
    </div>
    <div class="col-sm-12 col-md-12">
        <br>
       <div class="row radio-row">
         <input id="hasCasePending" type="radio" [(ngModel)]="hasCasePending" name="hasCasePending" [value]="true" [disabled] = "(data && data.hasCasePending != null) && !isEditable"/><br>
         <label for="single" class="radio-text">ಹೌದು / Yes</label>
       </div>
     <div  class="row radio-row">
          <input id="hasCasePending" type="radio" [(ngModel)]="hasCasePending"  name="hasCasePending" [value]="false" [disabled] = "(data && data.hasCasePending != null) && !isEditable"/><br>
          <label for="joint" class="radio-text">ಇಲ್ಲ / No</label>
    </div>
      </div>
      <div *ngIf ="hasCasePending">
      <div class="row" fxLayoutAlign="space-between">
         <div class="col-sm-6 col-md-6 application-form-row">
             <label class="form-label">ಹೌದಾದಲ್ಲಿ / If yesಪ್ರಕರಣ ಸಂಖ್ಯೆ, Case No : <span class="text-danger"> *</span></label>
         </div>
         <div class="col-sm-6 col-md-6 application-form-row"> 
            <input type="text" [(ngModel)]="caseNo" class="form-textbox" [readonly]="(data && data.caseNo) && !isEditable" />
         </div>
     </div>
     <div class="row" fxLayoutAlign="space-between">
        <div class="col-sm-6 col-md-6 application-form-row">
            <label class="form-label">ನ್ಯಾಯಾಲಯದ ಹೆಸರು,Name of the Court  :<span class="text-danger"> *</span></label>
        </div>
        <div class="col-sm-6 col-md-6 application-form-row"> 
            <input type="text" [(ngModel)]="courtName" class="form-textbox" [readonly]="(data && data.courtName) && !isEditable" />
        </div>
    </div>
     </div>
</div>
<div class="row content-area">
    <button class="btn btn-next" style="margin-left:75%" (click)="onSubmit()">Save & Continue</button>
</div>