
<div class="content-area">
        <div class="col-sm-10 offset-sm-1">
            <app-top-bar selectedTab="home"></app-top-bar>
            <app-header></app-header>
            
        </div>

         <div class="row tool-bar">
             <div >
                <div class="refresh-btn"> <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button></div>
                <!-- <div>
                <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
                    <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
                </button> 
            </div> -->
                    </div> 
                    <!-- <div class="col-sm-2 offset-sm-1 user-details">
                        <div class="key-text">
                            <br>
                            {{village.name}}
                       </div>
                        </div> -->
                    <div class="col-sm-2 offset-sm-1 user-details">
                        <div class="value-text">
                            Welcome <br> 
                        {{user['firstName']}}!
                        </div>
                        </div>
     </div>
     <div class="tool-bar" >
        <div class="col-sm-9 offset-sm-1">
            <div class ="button col-sm-10 offset-sm-1">
                   
                <button mat-button class="btn btn-primary" (click)="clickApplication($event,dateFilters)" id="selectapp"type="checkbox" [ngStyle]="{'background-color':'#0062cc'}">Select Application No</button>
               
                <button  mat-button  (click)="clickSelectPostField($event,dateFilters)"type="checkbox" [ngStyle]="{'background-color':'purple'}">Select Application No (Post Field Visit)</button>
            
        </div>
         <div class="filter-layout" >
            <div  *ngFor ="let dateFilter of dateFilters;let i=index;" >
                <button class="button" 
                [matTooltip]="matTooltipContent"
                matTooltipClass="allow-cr"
                (click)="clickDateFilter($event, dateFilter)"
                [ngStyle]="{'background-color':dateFilter.active?dateFilter.color:'grey'}" >
                {{dateFilter.label}} - ({{dateFilter.count}})
                <br>
                {{dateFilter.subtitle}}</button>
            </div>
        </div>
        <br>

        <div class="filter-layout col-md-9 offset-sm-1" *ngIf="isDateFilterSelected">

        <div class="approve-layout" *ngIf="isDateFilterSelected">

            <div *ngFor ="let approvedFilter of approvedFilters">
                <button class="button" [ngStyle]="{'background-color':approvedFilter.active ? approvedFilter.color : 'grey' }" 
            (click)="clickApprovedFilter($event,approvedFilter)" >{{approvedFilter.label}}</button>
            </div>
        </div>
   
        <div class="col-md-10 offset-sm-3" [hidden]="!show">
               <!-- data table -->
               <mat-card>
            
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                    <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                </ng-container>
                <ng-container matColumnDef="applicationNo">
                    <th mat-header-cell *matHeaderCellDef> Application No. </th>
                    <td mat-cell *matCellDef="let element" class="column-container">
                    <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
            </table>
            <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
        </mat-card>
        </div>
        
       
    </div>
</div>
    </div>
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
    </div>