<div fxFlex="100%">
    <div fxFlex="25" class="side-menu-container">
        <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
    </div>

    <div fxFlex="80" style="padding-left: 55px">
        <app-court-details [hidden]="selectedMenu['code'] != 'court_details'"></app-court-details>
        <div [hidden]="selectedMenu['code'] != 'property_details'">
            <!-- <div class="title" style="font-size: medium;">ಡಾ|| ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್ ಬಡಾವಣೆಯ ಭೂಸ್ವಾಧೀನ ಅಧಿಸೂಚನೆಯಲ್ಲಿ :-
                        In the Land Acquisition Notification of Dr. K. Shivarama Karanth Layout?
                        </div>
                        <br> -->

            <div fxLayout="row" fxLayoutAlign="space-between">

                <div style="width: 48%" class="row application-form-row">
                    <label class="form-label"> 1. ಕಟ್ಟಡವಿರುವ ನಿವೇಶನದ ಮಾಲೀಕರ ಹೆಸರು / Name of the Owner of the site with
                        Building : <span class="text-danger"> * </span></label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="buildingOwnerName" class="form-textbox"
                        [readonly]="(data && data.buildingOwnerName)" />
                </div>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 2. ನಿವೇಶನ ಸಂಖ್ಯೆ/Site No. <span class="text-danger"> * </span> </label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="siteNo" class="form-textbox"
                        [readonly]="(data && data.siteNo) && !isEditable" />
                </div>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 3. ನಿವೇಶನದ ವಿಸ್ತಾರ/ Extent of Site: <span class="text-danger"> * </span>
                    </label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="extentSite" class="form-textbox"
                        [readonly]="(data && data.extentSite) && !isEditable" />
                </div>
                <!-- <div>
                                <input type="text" (keypress)="omit_special_char($event)" mdInput name="name" [(ngModel)]="extentSite" placeholder="Company Name" minlength="3" required>
                            </div> -->
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 4. ಖರೀದಿಯ ದಿನಾಂಕ/ Date of Purchase : <span class="text-danger"> *
                        </span> </label>
                </div>
                <mat-form-field style="width: 50%" class="row date-form-row">
                    <input matInput maxlength="10"
                        onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        [matDatepicker]="picker" style="font-size: 10px;" [(ngModel)]="dateOfPurchase"
                        placeholder="Choose a date" [readonly]="(data && data.dateOfPurchase) && !isEditable"
                        (dateChange)="onChangeEvent($event)">
                    <mat-datepicker-toggle [disabled]="(data && data.dateOfPurchase) && !isEditable"
                        class="material-date-picker" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label">5. ಅನುಮೋದನೆಯೊಂದಿಗೆ ನಿರ್ಮಿಸಲಾಗಿದೆಯೇ?/ Constructed with approval?<span
                            class="text-danger">* </span>
                    </label>
                </div>
                <div style="width: 25%" class="row application-form-row">
                    <label class="radio-text-form" for="planApprovedYes">Yes</label>
                    <input class="form-radio" type="radio" value="yes" id="planApprovedYes" [(ngModel)]="isPlanApproved"
                        [readonly]="(data && data.isPlanApproved) && !isEditable"
                        [disabled]="(data && data.isPlanApproved) && !isEditable" name="isPlanApproved" />
                </div>
                <div style="width: 25%" class="row application-form-row">
                    <label class="radio-text-form2" for="planApprovedNo">No</label>
                    <input class="form-radio" type="radio" value="no" id="planApprovedNo" [(ngModel)]="isPlanApproved"
                        [readonly]="(data && data.isPlanApproved) && !isEditable"
                        [disabled]="(data && data.isPlanApproved) && !isEditable" name="isPlanApproved" />
                </div>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 6. ಕಟ್ಟಡದ ನಿರ್ಮಾಣದ ದಿನಾಂಕ/ Date of Construction of the Building : <span
                            class="text-danger"> *
                        </span> </label>
                </div>
                <mat-form-field style="width: 50%" class="row date-form-row">
                    <input matInput maxlength="10"
                        onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        [matDatepicker]="picker1" style="font-size: 10px;" [(ngModel)]="buildingConstructionDate"
                        placeholder="Choose a date" (dateChange)="clearYear()" (keypress)="clearYear()"
                        [readonly]="(data && data.buildingConstructionDate) && !isEditable && !buildingConstructionYear"
                        (dateChange)="onChangeEvent($event)">
                    <mat-datepicker-toggle
                        [disabled]="(data && data.buildingConstructionDate) && !isEditable && !buildingConstructionYear"
                        class="material-date-picker" matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="!buildingConstructionDate && !(data && data.buildingConstructionDate) ||isEditable"
                fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label style="margin-top: -40px" class="form-label">OR Enter year</label>
                </div>
                <div style="width: 20%; margin-top: -10px;" (keypress)="clearDate()" class="row application-form-row">
                    <input type="text" [(ngModel)]="buildingConstructionYear" class="form-textbox"
                        [readonly]="(data && data.buildingConstructionYear) && !isEditable"/>
                    </div>
            </div>
            <br>



            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 7. ನಿವೇಶನ ಹಾಗು ಕಟ್ಟಡವಿರುವ ಹಳ್ಳಿಯ ಹೆಸರು / Name of the Village where Site
                        and Building exists : </label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="villageName" class="form-textbox"
                        [readonly]="(data && data.villageName) && !isEditable" />
                </div>
                <!-- <div style="width: 50%"  class="row application-form-row">
                                <select class="form-textbox" [(ngModel)] = "villageSiteBuilding" (ngModelChange)="onVillageChanged($event)" [disabled]="data && data.siteDetailsVillageVO" >
                                    <option disabled="true">Select Village</option>
                                    <option *ngFor = "let village of villages" [value]="village.id">{{village.id}}. {{village.villageName}}</option>
                                </select>
                            </div> -->
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 8. ಕಟ್ಟಡ ಹಾಗು ನಿವೇಶನದ ಸರ್ವೇ ಸಂಖ್ಯೆ / Survey No of the site with Building
                        :
                    </label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="surveyNumber" class="form-textbox"
                        [readonly]="(data && data.surveyNumber) && !isEditable" />
                </div>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">

                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 9. ಪ್ರದೇಶ/ಲೆಔಟ್ /
                        Area/Layout : </label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="areaLayout" class="form-textbox"
                        [readonly]="(data && data.areaLayout) && !isEditable" />
                </div>
            </div>
            <br>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 10. ಕಟ್ಟಡದ ಪ್ರಕಾರ/ Type of Building :</label><span class="text-danger"> *</span>
                </div>
                <div style="width: 25%" class="row application-form-row">
                    <label class="radio-text-form" for="buildingType">RCC</label>
                    <input class="form-radio" type="radio" value="RCC" id="typeRcc" [(ngModel)]="buildingType"
                        [readonly]="(data && data.buildingType) && !isEditable"
                        [disabled]="(data && data.buildingType) && !isEditable" name="buildingType" />
                </div>
                <div style="width: 24%" class="row application-form-row">
                    <label class="radio-text-form2" for="buildingType">ACC</label>
                    <input class="form-radio" type="radio" value="ACC" id="typeAcc" [(ngModel)]="buildingType"
                        [readonly]="(data && data.buildingType) && !isEditable"
                        [disabled]="(data && data.buildingType) && !isEditable" name="buildingType" />
                </div>
                <!-- <div style="width: 50%"  class="row application-form-row">
                                <select class="form-textbox" [(ngModel)] = "buildingTypeId" [disabled]="data && data.buildingTypeId" >
                                    <option disabled="true">Type of Building</option>
                                    <option *ngFor = "let buildingType of buildingTypes" [value]="buildingType.id">{{buildingType.id}}. {{buildingType.buildingType}}</option>
                                </select>
                            </div> -->
            </div>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 10.a ಬಳಕೆ/Usage : <span class="text-danger"> *</span>
                    </label>
                    <label class="sub-label"> (Residential or Commercial)
                    </label>
                </div>
                <div style="width: 36%" class="row application-form-row">
                    <label class="radio-text-form" for="buildingUsage">Residential</label>
                    <input class="form-radio" type="radio" value="residential" id="res" [(ngModel)]="buildingUsage"
                        [disabled]="(data && data.buildingUsage) && !isEditable" name="buildingUsage" />
                </div>
                <div style="width: 25%" class="row application-form-row">
                    <label class="radio-text-form2" for="buildingUsage">Commercial</label>
                    <input class="form-radio" type="radio" value="commercial" id="com" [(ngModel)]="buildingUsage"
                        [disabled]="(data && data.buildingUsage) && !isEditable" name="buildingUsage" />
                </div>
            </div>
            <br>


            <div fxLayout="row" fxLayoutAlign="space-between">

                <div style="width: 50%" class="row application-form-row">
                    <label class="form-label"> 11. ಕ್ರಮಬದ್ಧಗೊಳಿಸಲು/ಮರು ರವಾನಿಸಲು ಸಲ್ಲಿಸಿರುವ ಅನಧಿಕೃತ ಕಟ್ಟಡದ ವಿವರ/
                        Particulars of Unauthorized Construction sought to be Regularized/Re-conveyed :</label>
                </div>
                <div style="width: 50%" class="row application-form-row">
                    <input type="text" [(ngModel)]="unauthorizedConstruction" class="form-textbox"
                        [readonly]="(data && data.unauthorizedConstruction) && !isEditable" />
                </div>
            </div>
            <br>

            <div fxLayout="row" style="margin-top: 20px">
                <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
            </div>
        </div>
    </div>
</div>