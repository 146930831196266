<div class="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
            SCRUTINY WORKBENCH
        </div>

        <div class="label col-sm-10" *ngIf="applicationData">

            <div class="col-sm-12 text-title">APPLICATION NO:{{applicationData.applicationNo}} ( {{title}} )</div>

            <div>
                <button (click)="clickBack()" class="back-link">Back</button>
            </div>
            <div>
                <button (click)="encloPage()" class="enclosure-link">Enclosure </button>
            </div>




            <br>
            <br>
            <br>
            <div class="sub-title">
                Basic Info
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Registration & Application No</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.applicationNo}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Date Of Registration</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.createdAt}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Name Of The Applicant</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.applicantName}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Father’s/Husband’s Name</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.fatherName}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Occupation</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.occupation}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Mobile Number</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.mobileNumber}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Aadhar Number</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.aadharNo}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Present Address of the Applicant</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.applicantAddress}}</label>
                </div>
            </div>
            <div class="sub-title">
                Property Details
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Name of the Owner of the site with Building</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.buildingOwnerName}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Site No</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.siteNo}}</label>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Extent of Site</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.extentSite}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Date of Purchase</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.dateOfPurchase}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Village name</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.villageName}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Survey No</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.surveyNumber}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Date of Construction of the Building</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.buildingConstructionDate}}{{applicationData.buildingConstructionYear}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Type of Building</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.buildingType}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Usage</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.buildingUsage}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Constructed with approval?</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.isPlanApproved}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Particulars of Unauthorized Construction sought to be Regularized/Re-conveyed</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.unauthorizedConstruction}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 ">
                    <label>Area/Layout</label>
                </div>
                <div class="col-sm-4">
                    <label>{{applicationData.areaLayout}}</label>
                </div>
            </div>
        </div>

    </div>

</div>