<div class="content-area">
  <app-top-bar selectedTab="home"></app-top-bar>
  <div style="margin-left:10%; margin-right:10%;">
    <app-header></app-header>
  </div>
  <div style="margin-left:10%; margin-right:10%;">
    <app-content-header></app-content-header>


    <!-- <div class="row profile-row">

      <div class="col-sm-4" *ngFor="let profile of profileContent; let i = index">
        <app-profile-card [name]="profile.name" [imagePath]="profile.imagePath" [content]="profile.content">
        </app-profile-card>
      </div>

    </div> -->
        <!-- </div> -->
    <!-- <div style="margin-left:10%; margin-right:10%;">
  <app-committee-header></app-committee-header> -->

    <!-- <div class="row profile-row">

<div class="col-sm-4" *ngFor="let profile of profileContent; let i = index">
  <app-profile-card-committee
  [name]="profile.name"  
 [imagePath]="profile.imagePath"
 [content]="profile.content">
</app-profile-card-committee>
</div> -->

    <!-- </div> -->
    <!-- </div>
<div style="margin-left:10%; margin-right:10%;"> -->
    <!-- <mat-card class="committee-card"> -->
    <!-- <mat-card-content class="para">
       <div class="title">{{ 'about_commitee.title' | translate }}</div>
        <p class="content">
          {{ 'about_commitee.content' | translate }}
        </p>
      </mat-card-content>
  </mat-card>  -->
    <app-map-image></app-map-image>

    <app-supreme-court-judgement></app-supreme-court-judgement>
  </div>
  <div style="margin-left:10%; margin-right:10%;">

    <label class="login-title">Please apply only if you own a building eligible for regularization under the Section 38D
      of the BDA act</label>
  </div><br><br><br>
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div style="text-align: center;">
      <button (click)="onCommitteeClick()" mat-fab
        style="height: 70px; width: 70px;background-color:#e95a4c;outline: none;">
        <img src="../assets/Leadership.png" style="max-width:50px;" />
      </button>
      <div fxLayoutAlign="center" style="text-align:center;font-size:large;color:#e95a4c;margin-top: 10%;">
        <app-login-button name="Leadership" [loginPath]="'/committee-login'" [disabled]="!enableComitteeUser">
        </app-login-button>
      </div>
    </div>
    <div style="text-align: center;">
      <button (click)="onScrutinyClick()" mat-fab
        style=" height: 70px;width: 70px;background-color:#448bdd;outline: none;">
        <img src="../assets/scrutiny.png" style="max-width:50px;" />
      </button>
      <div fxLayoutAlign="center" style="text-align:center;font-size:large;color:#448BDD;margin-top: 10%;">
        <app-login-button name="Scrutiny" [loginPath]="'/scrutiny-login'" [disabled]="!enableScrutinyUser">
        </app-login-button>
      </div>
    </div>
    <div style="text-align: center;">
      <button (click)="onApplicantClick()" mat-fab
        style="height: 70px; width: 70px;background-color:#34BFB7;outline: none;">
        <img src="../assets/online-application.png" style="max-width:50px;" />
      </button>
      <div fxLayoutAlign="center" style="text-align:center;font-size:large;color:#34BFB7;margin-top: 10%;">
        <app-login-button name="Online Application" [loginPath]="'/online-applicant-login'"
          [disabled]="!enableOnlineApplicationUser"></app-login-button>
      </div>
    </div>
    <div style="text-align: center;">
      <button (click)="onHelpDeskClick()" mat-fab
        style="height: 70px;width: 70px;background-color:#F2A36C;outline: none;">
        <img src="../assets/helpdesk.png" style="max-width:50px;" />
      </button>
      <div fxLayoutAlign="center" style="text-align:center;font-size:large;color:#F2A36C;margin-top: 10%;">
        <app-login-button name="Helpdesk" [loginPath]="'/helpdesk-login'" [disabled]="!enableHelpDeskUser">
        </app-login-button>
      </div>
    </div>

    <div style="text-align: center;">
      <button (click)="onFieldVisitClick()" mat-fab
        style="height: 70px;width: 70px;background-color:#c68c53;outline: none;">
        <img src="../assets/filedvisit.png" style="max-width:50px;" />
      </button>
      <div fxLayoutAlign="center" style="text-align:center;font-size:large;color:#c68c53;margin-top: 10%;">
        <app-login-button name="Field Visit" [loginPath]="'/field-visit-login'" [disabled]="!enableFieldUser">
        </app-login-button>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>