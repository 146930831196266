import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-statics',
  templateUrl: './statics.component.html',
  styleUrls: ['./statics.component.scss']
})
export class StaticsComponent implements OnInit {
@Output() staticsClick = new EventEmitter();

  public applicantCount;
  public ApplicantStatistics;
  public onlineApplicantCount;
  public helpdeskApplicantCount;
  public submitWithoutDocuments;
  public readyToScrutinized;
  public onlineApplicantPercent;
  public helpdeskApplicantPercent;
  public submitWithoutPercent;
  public readyToScrutinizedPercent;
  isCommitteeUser: boolean;
  constructor(private applicationService : ApplicationService,  private userService: UserService) { }

  ngOnInit(): void {
    this.isCommitteeUser = this.userService.getUserType()== this.userService.COMITTEE_USER;
    this.fetchAllApplicant();
  
  }
  fetchAllApplicant(){
    this.applicationService.fetchAllApplicant().subscribe(res =>{
      this.applicantCount = res;
      this.getApplicantStatistics();
    })
  }

  onStaticsClick(){
     this.staticsClick.emit();
  }

  getApplicantStatistics(){
    this.applicationService.getApplicantStatistics().subscribe(res =>{
      this.ApplicantStatistics = res;
      if(res && res.onlineApplicantCount != null && res.onlineApplicantCount != undefined && this.applicantCount){
        this.onlineApplicantCount = res.onlineApplicantCount;
        this.onlineApplicantPercent = ((res.onlineApplicantCount/this.applicantCount)*100).toFixed(2);
      } 
       if(res && res.helpdeskApplicantCount != null && res.helpdeskApplicantCount != undefined && this.applicantCount){
        this.helpdeskApplicantCount = res.helpdeskApplicantCount;
        this.helpdeskApplicantPercent = ((res.helpdeskApplicantCount/this.applicantCount)*100).toFixed(2);
      } 
      if(res && res.submitWithoutDocuments != null && res.submitWithoutDocuments != undefined && this.applicantCount){
        this.submitWithoutDocuments = res.submitWithoutDocuments;
        this.submitWithoutPercent = ((res.submitWithoutDocuments/this.applicantCount)*100).toFixed(2);
        this.readyToScrutinized = this.applicantCount - res.submitWithoutDocuments;
        this.readyToScrutinizedPercent = (((this.applicantCount -res.submitWithoutDocuments)/this.applicantCount)*100).toFixed(2);
      }
  })
}
}