import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatCardModule} from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HeaderComponent } from './header/header.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { MapComponent } from './map/map.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { NewsComponent } from './news/news.component';

import { LoginButtonComponent } from './login-button/login-button.component';
import { StaticsComponent } from './statics/statics.component';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import {MapsAPILoader} from '@agm/core';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { HomeComponent } from './home/home.component';
import { BasicInfoComponent } from './application-form/basic-info/basic-info.component';
import { ApplicantAddressComponent } from './application-form/applicant-address/applicant-address.component';
import { ProgressDialogComponent } from './widgets/progress-dialog/progress-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { PropertyDetailsComponent } from './application-form/property-details/property-details.component';
import { ChooseFormComponent } from './application-form/choose-form/choose-form.component';
import { SiteDetailsComponent } from './application-form/site-details/site-details.component';
import { BuildingDetailsComponent } from './application-form/building-details/building-details.component';
import { ResidentalHouseComponent } from './application-form/building-details/residental-house/residental-house.component';
import { CommercialBuildingComponent } from './application-form/building-details/commercial-building/commercial-building.component';
import { ApartmentComponent } from './application-form/building-details/apartment/apartment.component';
import { GatedCommunityComponent } from './application-form/building-details/gated-community/gated-community.component';
import { DetailsOfApprovalComponent } from './application-form/details-of-approval/details-of-approval.component';
import { BbdApprovedComponent } from './application-form/details-of-approval/bbd-approved/bbd-approved.component';
import { ApprovalFromPanchayatComponent } from './application-form/details-of-approval/approval-from-panchayat/approval-from-panchayat.component';
import { ApprovalOfBbmpComponent } from './application-form/details-of-approval/approval-of-bbmp/approval-of-bbmp.component';
import { ConversionOfOrderComponent } from './application-form/details-of-approval/conversion-of-order/conversion-of-order.component'
import {  MatDialogModule } from '@angular/material/dialog';
import { ProfileInfoModalComponent } from './profile-card/profile-info-modal/profile-info-modal.component';
import { ContactComponent } from './contact/contact.component';
import { MapImageComponent } from './map-image/map-image.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { MatTableModule } from '@angular/material/table';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { UploadEnclosureComponent } from './upload-enclosure/upload-enclosure.component';
import { ApplicantEnclosureListComponent } from './applicant-enclosure-list/applicant-enclosure-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldControl, MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FilterOptionComponent } from './filter-option/filter-option.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { ApplicationLoginTypeComponent } from './application-login-type/application-login-type.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { AcknowledgementComponent } from './application-form/acknowledgement/acknowledgement.component';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SupremeCourtJudgementComponent } from './supreme-court-judgement/supreme-court-judgement.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommencementCertificateComponent } from './application-form/miscellaneous/commencement-certificate/commencement-certificate.component';
import { CompletionCertificateComponent } from './application-form/miscellaneous/completion-certificate/completion-certificate.component';
import { OccupancyCertificateComponent } from './application-form/miscellaneous/occupancy-certificate/occupancy-certificate.component';
import { ElectricityConnectionComponent } from './application-form/miscellaneous/electricity-connection/electricity-connection.component';
import { WaterConnectionComponent } from './application-form/miscellaneous/water-connection/water-connection.component';
import { DetailsOfLoanAvailedComponent } from './application-form/miscellaneous/details-of-loan-availed/details-of-loan-availed.component';
import { CopyLastPaidReceiptComponent } from './application-form/miscellaneous/copy-last-paid-receipt/copy-last-paid-receipt.component';
import { CourtCasePendingComponent } from './application-form/miscellaneous/court-case-pending/court-case-pending.component';
import { ConstructWithoutApprovalComponent } from './application-form/details-of-approval/construct-without-approval/construct-without-approval.component';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { OtherPropertyDetailsComponent } from './application-form/building-details/other-property-details/other-property-details.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HelpDeskLoginComponent } from './help-desk-login/help-desk-login.component';
import { OnlineApplicationLoginComponent } from './online-application-login/online-application-login.component';
import { TokenInterceptorService } from 'src/interceptors/token-interceptor.service';
import { CommunityLoginComponent } from './community-login/community-login.component';
import { ApplicationStaticsComponent } from './application-statics/application-statics.component';
import { ApplicationBarChartComponent } from './application-bar-chart/application-bar-chart.component';
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import {MatNativeDateModule} from '@angular/material/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { InfoDialogComponent } from './application-form/info-dialog/info-dialog.component';
import { AdminComponent } from './admin/admin.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuditHelpdeskComponent } from './audit-helpdesk/audit-helpdesk.component';
import { AuditHelpdeskLoginComponent } from './audit-helpdesk-login/audit-helpdesk-login.component';
import { AuditHelpdeskChartComponent } from './audit-helpdesk-chart/audit-helpdesk-chart.component';
import { PreviewEnclosureComponent } from './preview-enclosure/preview-enclosure.component';

import { HeadlineNewsComponent } from './headline-news/headline-news.component';
import { NgMarqueeModule } from 'ng-marquee';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ScrutinyUserModule } from './scrutiny-user/scrutiny-user.module';
import { ScrutinyAdminModule } from './scrutiny-admin/scrutiny-admin.module';
import { SideMenuComponent } from './application-form/side-menu/side-menu.component';
import { FieldVisitUserModule } from './field-visit-user/field-visit-user.module';
import { PersonalInfoComponent } from './application-form/personal-info/personal-info.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { CourtDetailsComponent } from './application-form/court-details/court-details.component';
import { PrintDialogAcknowledgementComponent } from './application-form/print-dialog-acknowledgement/print-dialog-acknowledgement.component';
import { MatDividerModule } from '@angular/material/divider';
import { MomentModule } from 'ngx-moment';
import { CommitteeHeaderComponent } from './committee-header/committee-header.component';
import { ProfileCardCommitteeComponent } from './profile-card-committee/profile-card-committee.component';
import { MaterialModule } from './material.module';
import { CommiteeWorkbenchComponent } from './applicant-list/commitee-workbench/commitee-workbench.component';


@NgModule({
  declarations: [
    AppComponent,
    ContentHeaderComponent,
    MapComponent,
    ProfileCardComponent,
    NewsComponent,
    LoginButtonComponent,
    StaticsComponent,
    ApplicationFormComponent,
    HomeComponent,
    BasicInfoComponent,
    ApplicantAddressComponent,
    ProgressDialogComponent,
    PropertyDetailsComponent,
    CourtDetailsComponent,
    ChooseFormComponent,
    SiteDetailsComponent,
    BuildingDetailsComponent,
    ResidentalHouseComponent,
    CommercialBuildingComponent,
    ApartmentComponent,
    GatedCommunityComponent,
    DetailsOfApprovalComponent,
    BbdApprovedComponent,
    ApprovalFromPanchayatComponent,
    ApprovalOfBbmpComponent,
    ConversionOfOrderComponent,
    ProfileInfoModalComponent,
    ContactComponent,
    MapImageComponent,
    ApplicantListComponent,
    ApplicantFormComponent,
    UploadEnclosureComponent,
    ApplicantEnclosureListComponent,
    ConfirmationModalComponent,
    FilterOptionComponent,
    AdminLoginComponent,
    ApplicationLoginTypeComponent,
    AcknowledgementComponent,
    SupremeCourtJudgementComponent,
    CommencementCertificateComponent,
    CompletionCertificateComponent,
    OccupancyCertificateComponent,
    ElectricityConnectionComponent,
    WaterConnectionComponent,
    DetailsOfLoanAvailedComponent,
    CopyLastPaidReceiptComponent,
    CourtCasePendingComponent,
    ConstructWithoutApprovalComponent,
    PreviewDialogComponent,
    OtherPropertyDetailsComponent,
    HelpDeskLoginComponent,
    OnlineApplicationLoginComponent,
    CommunityLoginComponent,
    ApplicationStaticsComponent,
    ApplicationBarChartComponent,
    InfoDialogComponent,
    AdminComponent,
    AuditHelpdeskComponent,
    AuditHelpdeskLoginComponent,
    AuditHelpdeskChartComponent,
    PreviewEnclosureComponent,
    HeadlineNewsComponent,
    SideMenuComponent,
    PersonalInfoComponent,
    PrintDialogAcknowledgementComponent,
    CommitteeHeaderComponent,
    ProfileCardCommitteeComponent,
    CommiteeWorkbenchComponent
  ],
  imports: [
    BrowserModule,
    ScrutinyUserModule,
    FieldVisitUserModule,
    ScrutinyAdminModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatCardModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    GoogleChartsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FontAwesomeModule,
    FlexLayoutModule,
    WebcamModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HighchartsChartModule,
    NgMarqueeModule,
    CoreModule,
    SharedModule,
    MomentModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgcTmaffzfOLa9FPlpVYryFIww5jh8aZ8',
      libraries: ['places', 'geometry', 'drawing']
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  entryComponents: [ConfirmationModalComponent, ApplicantEnclosureListComponent, PreviewDialogComponent,],
  providers: [  
      {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
        multi: true
      },
      {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
  }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
