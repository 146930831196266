<div class="jumbotron">

<div class="row">
    <div class="col-sm-10 offset-sm-1">
        
<div class="row">
    <div class="col-sm-2 offset-md-1"> 
        <hr> 
    </div>
 <!-- <div class="col-sm-6">
  <h1 class="display-4 header-text">  {{ 'home.title' | translate }}</h1>
</div> -->
<div class="col-sm-2 offset-md-1">  
    <hr> 
</div>
</div>
</div>
</div>
<div class="row">
<div class="content">
    <p>In pursuance of the amendment to the Section 38(D) of the BDA act, Bangalore Development Authority (BDA) is empowered to regularize constructions on notified land across Bangalore in lieu of pertinent regularization fees. </p>
    <p>Consequently, BDA intends to implement the provisions pan-Bangalore by inviting applications for the process. 
      Post that, the applications would be subjected to sequence of activities including –</p>
    <ul>
        <li>Helpdesk at the BDA Headquarters; scalable to ROS across the city</li>
        <li>Scrutiny by concerned BDA officials </li>
        <li>Subsequent field visit to fulfil the purpose of</li>
        <li style="list-style-type:none;">
            <div>
                <ul>
                    <li>Identification of the Property</li>
                    <li>Determination of the extent of construction</li>
                    <li>Determination of the plot on which the construction has taken place</li>
                    <li>Determining the quantum of penal fee as per the guidance value</li>
                </ul>
            </div>
        </li>
    </ul>
    <p>and consequent Regularization & award by competent BDA authorities.</p>    
</div>

</div>
</div>
