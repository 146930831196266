<div class="container">
        <div>
            <app-top-bar selectedTab="home"></app-top-bar>
            <app-header></app-header>
        </div>
        <br>
       
        <div class="tool-bar">
            <div class="refresh-btn">
            <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
        </div>
         
            <div class="col-sm-3 offset-sm-1 user-details">
                <div class="value-text">
                    Welcome  <br>
                {{user['firstName']}} !
                </div>
                </div>
            </div>
        
        <div>
            <section>
               
                    <div class="col-sm-11 title">
                        ACTIVITY LOG
                    </div>
                  
                
               
                <!-- data table -->
                <mat-card>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sn">
                            <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                              </ng-container>
                        <!-- Position Column -->
                        <ng-container matColumnDef="applicationNo">
                            <th mat-header-cell *matHeaderCellDef> Application No. </th>
                            <td mat-cell *matCellDef="let element">
                                <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}} </a> 
                            </td>
                        </ng-container>
                        
                        <!-- Name Column -->
                        <ng-container matColumnDef="applicantName">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                    </table>
                    <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                    [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
                </mat-paginator>
                </mat-card>
            </section>
        </div>
        <app-footer></app-footer>
    </div>